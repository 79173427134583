export * from 'AdvoxComponent/CatalogWidget/CatalogWidget.config';

export const DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                variableWidth: true,
                swipeToSlide: true,
            },
        },
        {
            breakpoint: 99999,
            settings: {
                swipeToSlide: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: false,
                initialSlide: 0,
            },
        },
    ],
};
