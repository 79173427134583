import PropTypes from 'prop-types';

import { KeyValueTable as AdvoxKeyValueTable } from 'AdvoxComponent/KeyValueTable/KeyValueTable.component';
import Field from 'Component/Field';

import './KeyValueTable.style';

/** @namespace Pwabb/Component/KeyValueTable/Component */
export class KeyValueTable extends AdvoxKeyValueTable {
    static propTypes = {
        ...AdvoxKeyValueTable.propTypes,
        withRadio: PropTypes.bool,
        dataPairArray: PropTypes.array,
    };

    static defaultProps = {
        ...AdvoxKeyValueTable.defaultProps,
        withRadio: false,
        dataPairArray: [],
    };

    renderHeading() {
        const { title, isSelected, withRadio } = this.props;

        if (!title) {
            return null;
        }

        return (
            <tr>
                <th block="KeyValueTable" elem="Heading" colSpan={2}>
                    {withRadio ? <Field type="radio" attr={{ checked: isSelected }} /> : null}
                    {title}
                </th>
            </tr>
        );
    }

    renderTableRow(data) {
        const { key, prefix, label, component, source } = data ?? {};

        if (!source) {
            return null;
        }

        const value = this.getValueFromSource(data);
        return (
            <tr key={key}>
                <th>{prefix ? label + prefix : label}</th>
                <td>{typeof component !== 'function' ? value || '-' : component(value, source)}</td>
            </tr>
        );
    }

    get dataPairArray() {
        const { dataPairArray } = this.props;
        return dataPairArray ?? [];
    }
}

export default KeyValueTable;
