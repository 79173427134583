import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    CartDispatcher,
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS,
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { resetContractorData } from 'Store/Contractor/Contractor.actions';
import ContractorDispatcher from 'Store/Contractor/Contractor.dispatcher';
import { updateCustomerDetails, updateIsLoading, updateIsLocked } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { getAuthorizationToken, GRAPHQL_AUTH, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { history } from 'Util/History';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

export const CONTRACTOR = 'contractor';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

/**
 * My account actions
 * @class MyAccount
 * @namespace Pwabb/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    handleCustomerDataOnInit(dispatch) {
        if (isSignedIn()) {
            return this.requestCustomerData(dispatch);
        }

        BrowserDatabase.deleteItem(CUSTOMER);
        CartDispatcher.then(({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch));
    }

    requestCustomerData(dispatch) {
        return fetchQuery(MyAccountQuery.getCustomerQuery()).then(
            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/fetchQuery/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);

                return ContractorDispatcher.prepareRequest(dispatch);
            },
            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/fetchQuery/then/catch */
            (error) => {
                const {
                    extensions: { category },
                } = error[0];

                if (category === GRAPHQL_AUTH) {
                    dispatch(updateIsLocked(true));
                }
                dispatch(showNotification('error', getErrorMessage(error)));
            }
        );
    }

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const {
                    createCustomer: { customer },
                } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                setTimeout(() => {
                    history.push('/');
                }, 2000);
                // return this.signIn({ email }, dispatch);
            },

            /** @namespace Pwabb/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        super.logout(authTokenExpired, isWithNotification, dispatch);
        dispatch(resetContractorData());
    }
}

export default new MyAccountDispatcher();
