export const RESET_CONTRACTOR_DATA = 'RESET_CONTRACTOR_DATA';
export const UPDATE_CONTRACTOR_CURRENT_USER = 'UPDATE_CONTRACTOR_CURRENT_USER';
export const UPDATE_CONTRACTOR_CURRENT_CONTRACTOR = 'UPDATE_CONTRACTOR_CURRENT_CONTRACTOR';
export const UPDATE_CONTRACTOR_STRUCTURE = 'UPDATE_CONTRACTOR_STRUCTURE';
export const UPDATE_CONTRACTOR_USER_ROLE_LIST = 'UPDATE_CONTRACTOR_USER_ROLE_LIST';
export const UPDATE_CONTRACTOR_SUB_STRUCTURE = 'UPDATE_CONTRACTOR_SUB_STRUCTURE';

/** @namespace Pwabb/Store/Contractor/Actions/updateContractorCurrentUser */
export const updateContractorCurrentUser = (user) => ({
    type: UPDATE_CONTRACTOR_CURRENT_USER,
    user,
});

/** @namespace Pwabb/Store/Contractor/Actions/updateContractorCurrentContractor */
export const updateContractorCurrentContractor = (contractor) => ({
    type: UPDATE_CONTRACTOR_CURRENT_CONTRACTOR,
    contractor,
});

/** @namespace Pwabb/Store/Contractor/Actions/updateContractorStructure */
export const updateContractorStructure = (structure) => ({
    type: UPDATE_CONTRACTOR_STRUCTURE,
    structure,
});

/** @namespace Pwabb/Store/Contractor/Actions/updateContractorUserRoleList */
export const updateContractorUserRoleList = (availableRoles) => ({
    type: UPDATE_CONTRACTOR_USER_ROLE_LIST,
    availableRoles,
});

/** @namespace Pwabb/Store/Contractor/Actions/resetContractorData */
export const resetContractorData = () => ({
    type: RESET_CONTRACTOR_DATA,
});

/** @namespace Pwabb/Store/Contractor/Actions/updateContractorSubStructure */
export const updateContractorSubStructure = (subStructure) => ({
    type: UPDATE_CONTRACTOR_SUB_STRUCTURE,
    subStructure,
});
