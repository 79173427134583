import { connect } from 'react-redux';

import {
    CatalogWidgetContainer as SourceCatalogWidgetContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'AdvoxComponent/CatalogWidget/CatalogWidget.container';
import CatalogWidgetQuery from 'Query/CatalogWidget.query';
import { getIndexedProduct } from 'Util/Product';

/** @namespace Pwabb/Component/CatalogWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Pwabb/Component/CatalogWidget/Container */
export class CatalogWidgetContainer extends SourceCatalogWidgetContainer {
    performDataTransformations(data) {
        return data.map(({ image, ...rest }) => ({
            ...getIndexedProduct(rest),
            image: image || {},
            small_image: image || {},
        }));
    }

    getCatalogWidgetData() {
        const { code } = this.props;

        this.setState({ isLoading: true });

        this.fetchData(
            [
                CatalogWidgetQuery.getCatalogWidgetQuery(code, {
                    isPlp: true,
                    noVariantAttributes: true,
                }),
            ],
            ({ catalogWidget: { items = [], title } = {} } = {}) => {
                this.setState({
                    items: items?.length ? this.performDataTransformations(items) : [],
                    title,
                    isLoading: false,
                    settings: {},
                });
            },
            () => {
                this.setState({
                    items: [],
                    title: null,
                    isLoading: false,
                    settings: {},
                });
            }
        );
    }

    containerProps() {
        const { code, isMobile } = this.props;
        const { items, title, isLoading, settings } = this.state;

        return {
            items,
            title,
            isLoading,
            settings,
            code,
            isMobile,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogWidgetContainer);
