import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { typeOfContract } from './MyAccountCreateAccount.config';
/** @namespace Pwabb/Component/MyAccountCreateAccount/Form/getRegistrationFields */
export const getRegistrationFields = (props) => {
    const { getDataByNip, countries = [], countryId, onCountryChange, useFieldMask } = props;

    return (
        <fieldset block="MyAccountOverlay" elem="Legend">
            <Field
                type={FIELD_TYPE.email}
                label={__('E-mail address')}
                attr={{
                    id: 'email',
                    name: 'email',
                    placeholder: __('E-mail address'),
                    autocomplete: 'email',
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                    inputType: VALIDATION_INPUT_TYPE.email,
                }}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.text}
                label={__('First Name')}
                attr={{
                    id: 'first_name',
                    name: 'first_name',
                    placeholder: __('Firstname'),
                    autocomplete: 'given-name',
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.text}
                label={__('Last Name')}
                attr={{
                    id: 'last_name',
                    name: 'last_name',
                    placeholder: __('Lastname'),
                    autocomplete: 'family-name',
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.text}
                label={__('VAT Number')}
                attr={{
                    id: 'tax_number',
                    name: 'tax_number',
                    placeholder: __('VAT Number'),
                    format: useFieldMask ? '### ### ## ##' : null,
                    mask: '_',
                }}
                events={{
                    onBlur: getDataByNip,
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.text}
                label={__('Company name')}
                attr={{
                    id: 'name',
                    name: 'company_name',
                    placeholder: __('Company name'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <div block="Field" elem="HalfWidth">
                <Field
                    type={FIELD_TYPE.text}
                    label={__('Zip / Postal code')}
                    isHalfWidth
                    attr={{
                        id: 'zipCode',
                        name: 'post_code',
                        placeholder: __('Zip / Postal code'),
                        format: useFieldMask ? '##-###' : null,
                        mask: '_',
                    }}
                    validateOn={['onBlur']}
                    validationRule={{
                        isRequired: true,
                    }}
                    addRequiredTag
                />
                <Field
                    type={FIELD_TYPE.text}
                    label={__('City')}
                    isHalfWidth
                    attr={{
                        id: 'city',
                        name: 'city',
                        placeholder: __('City'),
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                    }}
                    addRequiredTag
                />
            </div>

            <Field
                type={FIELD_TYPE.text}
                label={__('Street')}
                attr={{
                    id: 'street',
                    name: 'street',
                    placeholder: __('Street'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
            <div block="Field" elem="HalfWidth">
                <Field
                    type={FIELD_TYPE.text}
                    label={__('Building number')}
                    isHalfWidth
                    attr={{
                        id: 'propertyNumber',
                        name: 'building_number',
                        placeholder: __('Building number'),
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                    }}
                    addRequiredTag
                />
                <Field
                    type={FIELD_TYPE.text}
                    label={__('Local number')}
                    isHalfWidth
                    attr={{
                        id: 'apartmentNumber',
                        name: 'apartment_number',
                        placeholder: __('Local number'),
                    }}
                    validateOn={['onChange']}
                />
            </div>
            <Field
                type={FIELD_TYPE.select}
                label={__('Country')}
                attr={{
                    id: 'address-country-id',
                    name: 'country_id',
                    defaultValue: countryId,
                    selectPlaceholder: __('Select country...'),
                }}
                variant="secondary"
                size="small"
                validateOn={['onChange']}
                events={{
                    onChange: onCountryChange,
                }}
                validationRule={{
                    isRequired: true,
                }}
                options={countries}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.tel}
                label={__('Telephone')}
                attr={{
                    name: 'phone_number',
                    placeholder: __('Telephone'),
                    format: useFieldMask ? '### ### ###' : null,
                    mask: '_',
                }}
                validateOn={['onBlur']}
                validationRule={{
                    inputType: VALIDATION_INPUT_TYPE.phone,
                    isRequired: true,
                }}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.tel}
                label={__('Self phone')}
                attr={{
                    name: 'mobile_phone_number',
                    placeholder: __('Self phone'),
                    format: useFieldMask ? '### ### ###' : null,
                    mask: '_',
                }}
                validateOn={['onBlur']}
            />
            <Field
                type={FIELD_TYPE.select}
                label={__('Type of contract')}
                attr={{
                    id: 'cooperation_type',
                    name: 'cooperation_type',
                    selectPlaceholder: __('Select'),
                }}
                validateOn={['onChange']}
                events={{
                    onChange: onCountryChange,
                }}
                variant="secondary"
                size="small"
                validationRule={{
                    isRequired: true,
                }}
                options={typeOfContract}
                addRequiredTag
            />
            <Field
                type={FIELD_TYPE.textarea}
                label={__('Notice')}
                attr={{
                    id: 'notes',
                    name: 'notes',
                    placeholder: __('Enter notices'),
                    rows: '5',
                    cols: '5',
                }}
                validateOn={['onChange']}
            />
        </fieldset>
    );
};
