import { MyAccountTabListItem as AdvoxMyAccountTabListItem } from 'AdvoxComponent/MyAccountTabListItem/MyAccountTabListItem.component';
import Icons from 'Component/Icons';

import './MyAccountTabListItem.override.style';

/** @namespace Pwabb/Component/MyAccountTabListItem/Component */
export class MyAccountTabListItem extends AdvoxMyAccountTabListItem {
    render() {
        const {
            children,
            tabEntry: [, { tabName, iconName }],
            isActive,
        } = this.props;

        return (
            <li block="MyAccountTabListItem" mods={{ isActive }}>
                <button block="MyAccountTabListItem" elem="Button" onClick={this.changeActiveTab} role="link">
                    {tabName && <Icons name={iconName} />}
                    {tabName}
                </button>
                {children}
            </li>
        );
    }
}

export default MyAccountTabListItem;
