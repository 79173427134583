import DiscountLabel from 'Component/DiscountLabel/DiscountLabel.component';
import Link from 'Component/Link';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { isSignedIn } from 'Util/Auth';
import { roundPercentage } from 'Util/Percentage/Percentage';

import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX,
} from './ProductPrice.config';

/** @namespace Pwabb/Component/ProductPrice/Component */
export class ProductPrice extends SourceProductPrice {
    pricePreviewRenderMap = {
        ...this.pricePreviewRenderMap,
        [PRODUCT_TYPE.simple]: this.renderDefaultPrice.bind(this),
        [PRODUCT_TYPE.virtual]: this.renderDefaultPrice.bind(this),
        [PRODUCT_TYPE.configurable]: this.renderDefaultPrice.bind(this),
    };

    renderDiscountLabel() {
        const { discountPercentage } = this.props;
        return <DiscountLabel value={roundPercentage(discountPercentage)} />;
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {}, originalPrice = {}, originalPriceExclTax = {} } = {},
            label,
            isPDP,
            showOnlyRetailPrices,
            isComparePage,
        } = this.props;

        return (
            <>
                <div block="ProductPrice" elem="Wrapper">
                    <div block="ProductPrice" elem="Left">
                        {__('Retail price')}
                        {!isPDP ? ':' : ''}
                    </div>
                    <div block="ProductPrice" elem="Right">
                        {this.renderPriceWithOrWithoutTax(
                            originalPrice,
                            originalPriceExclTax,
                            defaultLabel || label,
                            true,
                            !!isPDP
                        )}
                    </div>
                </div>
                {!showOnlyRetailPrices ? (
                    <div block="ProductPrice" elem="Wrapper">
                        <div block="ProductPrice" elem="Left">
                            {__('Your price')}
                            {!isPDP ? ':' : ''}
                            {!isComparePage ? this.renderDiscountLabel() : ''}
                        </div>
                        <div block="ProductPrice" elem="Right">
                            {this.renderPriceWithOrWithoutTax(
                                finalPrice,
                                finalPriceExclTax,
                                defaultLabel || label,
                                !!isPDP,
                                true
                            )}
                        </div>
                        {isComparePage ? this.renderDiscountLabel() : ''}
                    </div>
                ) : null}
            </>
        );
    }

    renderPriceWithOrWithoutTax(basePrice, taxPrice, label, withTax = true, withoutTax = true) {
        const { displayTaxInPrice } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        return (
            <>
                {withoutTax && this.renderSubPrice(taxPrice)}
                {withTax && this.renderPrice(basePrice, label)}
            </>
        );
    }

    renderPrice(price) {
        const { value: priceValue, valueFormatted: priceFormatted = 0 } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        return (
            <div block="ProductPrice" elem="Price">
                <span itemProp={itemProp} content={content}>
                    {this.renderPriceType()}
                </span>
                {priceFormatted}
            </div>
        );
    }

    renderPriceType() {
        const { displayTaxInPrice } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return <span>{__('net')}</span>;
        }

        return <span>{__('gross')}</span>;
    }

    renderSubPrice(price) {
        const { value: priceExclTax = 0, valueFormatted: priceExclTaxFormatted = 0 } = price;

        if (!priceExclTax) {
            return null;
        }

        return (
            <div aria-label={__('Current product price excl. tax')} block="ProductPrice" elem="Price">
                <span>{__('net')}</span>
                {priceExclTaxFormatted}
            </div>
        );
    }

    render() {
        const {
            price: { finalPrice, originalPrice, finalPrice: { value: finalPriceValue = 0 } = {} } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
            isPDP,
        } = this.props;

        if (!isSignedIn()) {
            return (
                <Link to={ACCOUNT_LOGIN_URL} block="ProductPrice" elem="NotLoggedInPrice">
                    {__('Please login to see the price')}
                </Link>
            );
        }

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
                block="ProductPrice"
                mods={{ hasDiscount: discountPercentage !== 0, isPreview, notPDP: !isPDP }}
                mix={mix}
                aria-label={`Product price: ${finalPriceValue}`}
            >
                {isPreview && renderer && renderer()}
                {(!isPreview || !renderer) && this.renderDefaultPrice()}
            </div>
        );
    }
}

export default ProductPrice;
