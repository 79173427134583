export const SHOW_VAT_NUMBER_REQUIRED = 'req';
export const SHOW_VAT_NUMBER_OPTIONAL = 'opt';
export const CONFIRMATION_REQUIRED = 'confirmation_required';

export const typeOfContract = [
    {
        value: 'sklep stacjonarny',
        name: 'sklep stacjonarny',
        id: 'sklep stacjonarny',
        label: 'sklep stacjonarny',
    },
    {
        value: 'sklep internetowy',
        name: 'sklep internetowy',
        id: 'sklep internetowy',
        label: 'sklep internetowy',
    },
    {
        value: 'dropshipping',
        name: 'dropshipping',
        id: 'dropshipping',
        label: 'dropshipping',
    },
    {
        value: 'Klient zagraniczny',
        name: 'Klient zagraniczny',
        id: 'Klient zagraniczny',
        label: 'Klient zagraniczny',
    },
];
