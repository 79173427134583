import { getQueryParam } from 'SourceUtil/Url/Url';

export * from 'AdvoxUtil/Url/Url';

/** @namespace Pwabb/Util/Url/parseCustomFiltersToObject */
export const parseCustomFiltersToObject = (location) => {
    if (!location || !getQueryParam('customFilters', location)) {
        return {};
    }

    return Object.fromEntries(
        getQueryParam('customFilters', location)
            .split(';')
            .map((filter) => filter.split(':'))
            .map(([name, value]) => {
                if (value.includes('_') && value !== 'IN_STOCK') {
                    const vS = value.split('_');

                    return [
                        name,
                        {
                            min: parseInt(vS[0], 10) ?? 0,
                            max: parseInt(vS[1], 10) ?? 0,
                        },
                    ];
                }

                return [name, value];
            })
    );
};
