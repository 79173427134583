import ContractorQuery from 'Query/Contractor.query';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import {
    updateContractorCurrentContractor,
    updateContractorCurrentUser,
    updateContractorStructure,
    updateContractorSubStructure,
    updateContractorUserRoleList,
} from './Contractor.actions';

export const DEPARTMENT_ROLE = 'department';
export const ADMIN_ROLE = 'admin';
export const SUBSTRUCTURE_MAX_PAGE_SIZE = 300;
export const AUTHORITIES = 'authorities';
export const ALL_AUTHORITIES = 'all';

/** @namespace Pwabb/Store/Contractor/Dispatcher */
export class ContractorDispatcher {
    static getStructureMap(structure) {
        if (!structure) {
            return null;
        }

        if (!Array.isArray(structure)) {
            return this.getStructureMap([structure]);
        }

        return structure.reduce(
            (map, { id, name, substructures }) => ({
                ...map,
                [id]: name,
                ...(Array.isArray(substructures) && substructures.length > 0
                    ? this.getStructureMap(substructures)
                    : {}),
            }),
            {}
        );
    }

    static getStructureOptions(structure) {
        return Object.entries(this.getStructureMap(structure)).map(([value, label]) => ({
            label,
            value,
        }));
    }

    static getDepartmentRoleId(roles) {
        if (!Array.isArray(roles)) {
            return null;
        }

        return roles.find(({ name } = {}) => name === DEPARTMENT_ROLE)?.id;
    }

    static isAdmin(roles, currentRoleId) {
        if (!Array.isArray(roles) || !currentRoleId) {
            return false;
        }

        return roles.find(({ id } = {}) => String(id) === String(currentRoleId))?.name === ADMIN_ROLE;
    }

    prepareRequest(dispatch) {
        const authorities = BrowserDatabase.getItem(AUTHORITIES) || [];
        const isAllAuthorities = authorities?.includes(ALL_AUTHORITIES);
        const queryData = [
            ContractorQuery.getCurrentUserQuery(),
            ContractorQuery.getContractorStructureQuery(),
            ContractorQuery.getUserRolesQuery(),
            ContractorQuery.getContractorQuery(),
        ];

        if (isAllAuthorities) {
            queryData.push(ContractorQuery.getContractorSubstructuresQuery(1, SUBSTRUCTURE_MAX_PAGE_SIZE));
        }

        return fetchQuery(queryData).then(
            /** @namespace Pwabb/Store/Contractor/Dispatcher/ContractorDispatcher/prepareRequest/fetchQuery/then */
            ({
                getContractorForLoggedInCustomer = {},
                getCurrentUser = {},
                getContractorStructure = {},
                getUserRoles = [],
                getSubstructures = {},
            }) => {
                dispatch(updateContractorCurrentContractor(getContractorForLoggedInCustomer));
                dispatch(updateContractorCurrentUser(getCurrentUser));
                dispatch(updateContractorStructure(getContractorStructure));
                dispatch(updateContractorUserRoleList(getUserRoles));
                dispatch(updateContractorSubStructure(getSubstructures));
            },
            /** @namespace Pwabb/Store/Contractor/Dispatcher/ContractorDispatcher/prepareRequest/fetchQuery/then/catch */
            (error) => {
                dispatch(showNotification('error', getErrorMessage(error), error));
            }
        );
    }
}

export default new ContractorDispatcher();
