import { VALIDATION_INPUT_TYPE_NUMBER, VALIDATION_MESSAGES, VALIDATION_RULES } from 'Util/Validator/Config';
import zipCodes from 'Util/Validator/Zip.config';

export * from 'SourceUtil/Validator/Validator';

/** @namespace Pwabb/Util/Validator/validate */
export const validate = (value, rule) => {
    const {
        isRequired,
        isRequiredMyAccount,
        inputType,
        match,
        matchPassword,
        validateZipFormat,
        range,
        customErrorMessages: { onRequirementFail, onInputTypeFail, onMatchFail, onRangeFailMin, onRangeFailMax } = {},
    } = rule;

    const output = {
        value,
        errorMessages: [],
    };

    // #region IS REQUIRED
    if (isRequired && !value) {
        output.errorMessages.push(onRequirementFail || VALIDATION_MESSAGES.isRequired);
    }
    // #endregion

    if (isRequiredMyAccount && !value) {
        output.errorMessages.push(onRequirementFail || VALIDATION_MESSAGES.isRequiredMyAccount);
    }

    // #region INPUT TYPE
    if (inputType && value && !value.match(VALIDATION_RULES[inputType])) {
        output.errorMessages.push(onInputTypeFail || VALIDATION_MESSAGES[inputType]);
    }
    // #endregion

    // #region MATCH
    if (typeof match === 'function') {
        const response = match(value);

        if (response !== true) {
            output.errorMessages.push(response === false ? onMatchFail || VALIDATION_MESSAGES.match : response);
        }
    } else if (match && !value.match(match)) {
        output.errorMessages.push(onMatchFail || VALIDATION_MESSAGES.match);
    }
    // #endregion

    // #region MATCH_PASSWORD
    if (matchPassword) {
        const { passwordClassesLength } = matchPassword;

        let counter = 0;

        if (value.match(/\d+/)) {
            counter++;
        }

        if (value.match(/[a-z]+/)) {
            counter++;
        }

        if (value.match(/[A-Z]+/)) {
            counter++;
        }

        if (value.match(/[^a-zA-Z0-9]+/)) {
            counter++;
        }

        if (counter < passwordClassesLength) {
            output.errorMessages.push(
                __(
                    'Minimum of different classes of characters in password is %s. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.',
                    passwordClassesLength
                )
            );
        }
    }
    // #endregion

    // #region ZIP FORMAT
    if (validateZipFormat) {
        const { countryId } = validateZipFormat;
        const examples = [];

        if (zipCodes[countryId] && value) {
            if (Array.isArray(zipCodes[countryId].code)) {
                zipCodes[countryId].code.forEach(({ example, text }) => {
                    if (!value.match(text)) {
                        examples.push(example);
                    }
                });
            } else {
                const { example, text } = zipCodes[countryId].code;

                if (!value.match(text)) {
                    examples.push(example);
                }
            }
        }

        if (examples.length) {
            output.errorMessages.push(__('Correct zip format: %s', examples.join(', ')));
        }
    }
    // #endregion

    // #region RANGE
    if (range) {
        const { min, max } = range;
        const isNumber = !!VALIDATION_INPUT_TYPE_NUMBER[inputType];

        if (isNumber) {
            if (min && +value < min) {
                output.errorMessages.push(onRangeFailMin || __('Minimal value is %s!', min));
            }

            if (max && +value > max) {
                output.errorMessages.push(onRangeFailMax || __('Maximum value is %s!', max));
            }
        } else {
            if (min && value.length < min) {
                output.errorMessages.push(onRangeFailMin || __('Minimum %s characters!', min));
            }

            if (max && value.length > max) {
                output.errorMessages.push(onRangeFailMax || __('Maximum %s characters!', max));
            }
        }
    }
    // #endregion

    const { errorMessages } = output;
    return errorMessages.length === 0 ? true : output;
};

export default validate;
