import { MyAccountCustomerForm as AdvoxMyAccountCustomerForm } from 'AdvoxComponent/MyAccountCustomerForm/MyAccountCustomerForm.component';

import myAccountCustomerForm from './MyAccountCustomerForm.form';

/** @namespace Pwabb/Component/MyAccountCustomerForm/Component */
export class MyAccountCustomerForm extends AdvoxMyAccountCustomerForm {
    get fieldMap() {
        const {
            showTaxVatNumber,
            customer: { firstname = '', lastname = '', taxvat = '', dob = '' },
        } = this.props;

        return myAccountCustomerForm({
            showTaxVatNumber,
            firstname,
            lastname,
            taxvat,
            dob,
        });
    }

    renderActions() {
        return (
            <button type="submit" block="Button" mix={{ block: 'MyAccount', elem: 'Button' }}>
                {__('Save customer')}
            </button>
        );
    }
}

export default MyAccountCustomerForm;
