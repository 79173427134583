import parser from 'html-react-parser';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Html from 'Component/Html';
import Loader from 'Component/Loader';

/** @namespace Pwabb/Component/Consents/Component */
export class Consents extends PureComponent {
    renderConsent(data) {
        const { updateSelectedConsentIds, currentPlace, requireInfo, onChange } = this.props;
        const { consent_id, is_checkbox, is_required: isRequired, description } = data;
        if (!is_checkbox) {
            return <Html key={consent_id} content={description} />;
        }

        const requiredTemplate = (
            <span mix={{ block: 'Consents', elem: 'Label', mods: { isRequired } }}>({__('required')})</span>
        );

        const consentId = !currentPlace ? consent_id : `${currentPlace}${consent_id}`;

        return (
            <div block="Consents" elem="FieldWrapper" key={consentId}>
                <Field
                    type={FIELD_TYPE.checkbox}
                    mix={{ block: 'Consents', elem: 'Field', mods: { isRequired } }}
                    attr={{
                        id: consentId,
                        name: consentId,
                        value: consentId,
                    }}
                    validationRule={{
                        isRequired,
                    }}
                    label={
                        <>
                            {parser(description)} {isRequired && requireInfo && requiredTemplate}
                        </>
                    }
                    events={{
                        onChange: (event) => {
                            onChange(event);
                            updateSelectedConsentIds(consentId, event);
                        },
                    }}
                />
            </div>
        );
    }

    render() {
        const { data } = this.props;
        if (data === null) {
            return <Loader isLoading />;
        }

        if (!data || !data.length) {
            return null;
        }

        return <div block="Consents">{data.map((item) => this.renderConsent(item))}</div>;
    }
}

export default Consents;
