import { SET_ANNOUNCEMENT } from './Announcement.action';

/** @namespace Pwabb/Store/Announcement/Reducer/getInitialState */
export const getInitialState = () => ({
    announcement: null,
    announcementBackgroundColor: null,
});

/** @namespace Pwabb/Store/Announcement/Reducer/AnnouncementReducer */
export const AnnouncementReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case SET_ANNOUNCEMENT:
            const { announcement } = action;

            return {
                ...state,
                announcement,
            };
        default:
            return state;
    }
};

export default AnnouncementReducer;
