import { FIELD_TYPE as SOURCE_FIELD_TYPE } from 'SourceComponent/Field/Field.config';

export * from 'SourceComponent/Field/Field.config';

export const FIELD_TYPE = {
    ...SOURCE_FIELD_TYPE,
    dateRange: 'date-range',
};

export default FIELD_TYPE;
