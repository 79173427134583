import { connect } from 'react-redux';

import Check from './Check.component';

/** @namespace Advox/Permissions/Component/Check/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    authorities: state.MyAccountReducer.authorities,
});

/** @namespace Advox/Permissions/Component/Check/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Check);
