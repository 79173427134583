import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { EventsType, FieldAttrType } from 'Type/Field.type';

import FieldDatePicker from './FieldDatePicker.component';
import { DEFAULT_VALUE } from './FieldDatePicker.config';

/** @namespace Pwabb/Component/FieldDatePicker/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStoreCode: state.ConfigReducer.code,
});

/** @namespace Pwabb/Component/FieldDatePicker/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwabb/Component/FieldDatePicker/Container */
export class FieldDatePickerContainer extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        selectsRange: PropTypes.bool,
    };

    static defaultProps = {
        selectsRange: false,
    };

    static getValue(props) {
        const { attr: { defaultValue, value } = {}, selectsRange } = props;

        if (value) {
            if (Array.isArray(value)) {
                return [value[0] ?? DEFAULT_VALUE, value[1] ?? DEFAULT_VALUE];
            }

            return value ?? DEFAULT_VALUE;
        }

        if (defaultValue) {
            if (Array.isArray(defaultValue)) {
                return [defaultValue[0] ?? DEFAULT_VALUE, defaultValue[1] ?? DEFAULT_VALUE];
            }

            return defaultValue ?? DEFAULT_VALUE;
        }

        return selectsRange ? [DEFAULT_VALUE, DEFAULT_VALUE] : DEFAULT_VALUE;
    }

    containerFunctions = {
        onEvent: this.onEvent.bind(this),
        onReference: this.onReference.bind(this),
    };

    datepickerRef = createRef();

    inputRef = createRef();

    componentDidUpdate(prevProps) {
        const { attr: { value } = {} } = this.props;
        const { attr: { value: prevValue } = {} } = prevProps;

        if (prevValue !== value) {
            this.setState({
                value: FieldDatePickerContainer.getValue(this.props),
            });
        }
    }

    __construct() {
        this.state = {
            value: FieldDatePickerContainer.getValue(this.props),
        };
    }

    containerProps() {
        const { attr: { disabled } = {}, label, attr, events, isDisabled, setRef, selectsRange } = this.props;
        const { value } = this.state;

        return {
            attr,
            label,
            events,
            disabled: disabled || isDisabled,
            setRef,
            value,
            selectsRange,
        };
    }

    onReference(reference) {
        const { setRef } = this.props;
        const { input } = reference ?? {};

        if (input) {
            if (this.inputRef.current !== input) {
                this.inputRef.current = input;
            }

            if (this.datepickerRef.current !== reference) {
                this.datepickerRef.current = reference;
            }

            if (typeof setRef === 'function') {
                setRef(input);
            }
        }
    }

    onEvent(eventName) {
        return (value, event) => {
            const { events = {} } = this.props;

            if (eventName === 'onChange') {
                this.setState({ value });
            }

            if (typeof events[eventName] === 'function') {
                events[eventName](value, event);
            }
        };
    }

    render() {
        return <FieldDatePicker {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDatePickerContainer);
