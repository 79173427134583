// new
import { withRouter } from 'react-router-dom';

import { ProductCard as SourceProductCard } from 'AdvoxComponent/ProductCard/ProductCard.component';
import AddToCart from 'Component/AddToCart';
import DiscountLabel from 'Component/DiscountLabel/DiscountLabel.component';
import Image from 'Component/Image';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import Loader from 'Component/Loader';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { B2B, OUTSOURCING } from 'Component/ProductActions/ProductActions.config';
import { MAX_NAME_LENGTH } from 'Component/ProductCard/ProductCard.config';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes';
import ProductReviewRating from 'Component/ProductReviewRating';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import RelatedProductsByAttribute from 'Component/RelatedProductsByAttribute';
import TypographyHeader from 'Component/TypographyHeader';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { isSignedIn } from 'Util/Auth';
import { roundPercentage } from 'Util/Percentage/Percentage';
import { calculateProductAvailability, displaySalableQtyMsg, filterConfigurableOptions } from 'Util/Product';

import productPlaceholder from '../../assets/images/productPlaceholder.png';

/** @namespace Pwabb/Component/ProductCard/Component */
export class ProductCard extends SourceProductCard {
    state = {
        relatedImage: null,
    };

    renderPicture(mix = {}) {
        const {
            product: { id, name },
            thumbnail,
        } = this.props;

        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={thumbnail}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
                useNativeLazyLoading={false}
                lazyLoadingType="productTile"
            />
        );

        return thumbnail ? (
            <>
                {this.sharedComponent}
                <ImageLazyLoading
                    style={{ display: 'none' }}
                    alt={name}
                    src={thumbnail}
                    lazyLoadingType="productTile"
                />
            </>
        ) : (
            <img src={productPlaceholder} alt={name} />
        );
    }

    renderProductActionsOnHover() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return <>{this.renderProductActions()}</>;
    }

    renderRelatedProductsByAttribute() {
        const {
            product: { related_products_by_attribute = [], related_products_by_attribute_label = '' },
        } = this.props;

        return (
            <RelatedProductsByAttribute
                products={related_products_by_attribute}
                variant="secondary"
                onMouseEnter={(image) => {
                    this.setState({
                        relatedImage: image,
                    });
                }}
                onMouseLeave={() => {
                    this.setState({
                        relatedImage: null,
                    });
                }}
                label={related_products_by_attribute_label}
            />
        );
    }

    renderVisibleOnHover() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return <>{this.renderRelatedProductsByAttribute()}</>;
    }

    renderAlternativePicture() {
        const {
            product: { id, name, alternative_hover_image },
        } = this.props;

        return alternative_hover_image ? (
            <Image
                src={alternative_hover_image}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={!id}
                useNativeLazyLoading={false}
                lazyLoadingType="productTile"
            />
        ) : null;
    }

    renderRelatedProduct() {
        const { relatedImage } = this.state;

        return relatedImage ? (
            <Image
                src={relatedImage}
                alt="RelatedProduct"
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={false}
            />
        ) : null;
    }

    renderName() {
        const {
            product: { name },
            device,
        } = this.props;
        let strippedName = null;

        if (device.isMobile && name && name.length > MAX_NAME_LENGTH) {
            strippedName = name.substring(0, MAX_NAME_LENGTH);
        }

        return (
            <TypographyHeader block="ProductCard" elem="Name" mods={{ isLoaded: !!name }} variant="normal" tag="h3">
                {strippedName ? `${strippedName}...` : name}
            </TypographyHeader>
        );
    }

    renderAddToCart() {
        const { layout, showSelectOptionsNotification, inStock } = this.props;

        if (inStock && this.requiresConfiguration()) {
            return (
                <button block="Button AddToCart" mods={{ layout }} onClick={showSelectOptionsNotification}>
                    {__('Add to cart')}
                </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const { addToCart, inStock, quantity, getActiveProduct, device, showOnlyRetailPrices } = this.props;

        return (
            <AddToCart
                mix={{
                    block: this.className,
                    elem: 'AddToCart',
                    mods: { isRetailPricesOnly: showOnlyRetailPrices },
                }}
                addToCart={addToCart}
                isDisabled={!inStock}
                isIconEnabled={device.isMobile || device.isTablet}
                layout={layout}
                quantity={quantity}
                product={getActiveProduct()}
            />
        );
    }

    renderAddToCartField() {
        const {
            inStock,
            product: { type_id: type },
        } = this.props;

        if (!inStock) return this.renderAddToCart();
        return (
            <div
                block="ProductCard"
                elem="AddToCartWrapper"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                mods={{ isSimple: type === PRODUCT_TYPE.simple }}
            >
                {this.renderQuantityChanger()}
                {this.renderAddToCart()}
            </div>
        );
    }

    getConfigurableAttributes() {
        const {
            product: { configurable_options: configurableOptions = {}, variants = {} },
        } = this.props;

        return filterConfigurableOptions(configurableOptions, variants);
    }

    renderDiscountLabel() {
        const {
            productPrice: {
                price: {
                    discount: { percentOff },
                },
            },
            inStock,
            showOnlyRetailPrices,
        } = this.props;

        if (!inStock || !isSignedIn() || showOnlyRetailPrices) {
            return null;
        }

        return (
            <DiscountLabel
                value={roundPercentage(percentOff)}
                mix={{ block: 'ProductCard', elem: 'DiscountLabel' }}
                isProductCard
            />
        );
    }

    renderWishlistButton() {
        const { magentoProduct, isWishlistEnabled, showSelectOptionsNotification, getActiveProduct } = this.props;

        if (magentoProduct.length === 0 || !isWishlistEnabled) {
            return null;
        }

        const { type_id } = getActiveProduct();
        const isSimpleProduct = type_id === PRODUCT_TYPE.simple;

        return (
            <ProductWishlistButton
                magentoProduct={magentoProduct}
                mix={{
                    block: this.className,
                    elem: 'WishListButton',
                }}
                showSelectOptionsNotification={!isSimpleProduct ? showSelectOptionsNotification : false}
            />
        );
    }

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div block="ProductActions" elem="AttributesWrapper">
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                    numberOfPlaceholders={[2, 4]}
                    updateAddToCartTriggeredWithError={updateAddToCartTriggeredWithError}
                    addToCartTriggeredWithError={addToCartTriggeredWithError}
                    mix={{ block: this.className, elem: 'Attributes' }}
                    parameters={parameters}
                    variants={variants}
                    updateConfigurableVariant={setActiveProduct}
                    configurable_options={this.getConfigurableAttributes()}
                    isContentExpanded
                    inStock={inStock}
                    showProductAttributeAsLink={false}
                />
            </div>
        );
    }

    renderPrice() {
        const { inStock, getActiveProduct, product: { type_id: baseType } = {} } = this.props;

        const { price_range: priceRange, type_id: typeId } = getActiveProduct();

        if (!inStock) {
            return null;
        }

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        return super.renderPrice(notConfigured);
    }

    renderCardContent() {
        const { renderContent, sliderMode } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <div block="ProductCard" elem="Labels">
                                {this.renderDiscountLabel()}
                                {this.renderLabels()}
                            </div>
                            <div block="ProductCard" elem="LabelsWithActionsVisibleOnHover">
                                {this.renderProductActionsOnHover()}
                            </div>
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureAlternative">
                                {this.renderAlternativePicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureRelatedProduct">
                                {this.renderRelatedProduct()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            {this.renderName()}
                            <div block="ProductCard" elem="Price">
                                {this.renderPrice()}
                            </div>
                            {!sliderMode && this.renderConfigurableOptions()}
                            {!sliderMode && this.renderAddToCartField()}
                            {this.renderProductAvailability()}
                        </div>
                    </>
                )}
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </>
        );
    }

    renderProductAvailability() {
        const {
            product: { id = 0 },
            getActiveProduct,
            product,
        } = this.props;

        if (!id) {
            return null;
        }

        const activeProduct = getActiveProduct();
        const salableQty48 = calculateProductAvailability(B2B, product, activeProduct);
        const salableQty2W = calculateProductAvailability(OUTSOURCING, product, activeProduct);

        return (
            <div block="ProductCard" elem="Availability">
                {salableQty48 ? (
                    <div block="ProductCard" elem="AvailabilityInfo">
                        <span>{__('Shipping within 48 hours:')}</span>
                        <span block="ProductCard" elem="AvailabilityMsg">
                            {displaySalableQtyMsg(salableQty48)}
                        </span>
                    </div>
                ) : null}
                {salableQty2W ? (
                    <div block="ProductCard" elem="AvailabilityInfo">
                        <span>{__('Shipping within 2 weeks:')}</span>
                        <span block="ProductCard" elem="AvailabilityMsg">
                            {displaySalableQtyMsg(salableQty2W)}
                        </span>
                    </div>
                ) : null}
            </div>
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <div block="ProductCard" elem="Labels">
                                {this.renderDiscountLabel()}
                                {this.renderLabels()}
                            </div>
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureAlternative">
                                {this.renderAlternativePicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureRelatedProduct">
                                {this.renderRelatedProduct()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content" mods={{ layout }}>
                            <div block="ProductCard" elem="MainInfo">
                                {this.renderMainDetails()}
                                {this.renderBrand()}
                                {this.renderPrice()}
                                {this.renderConfigurableOptions()}
                                {this.renderAddToCartField()}
                                {this.renderProductAvailability()}
                            </div>
                            <div block="ProductCard" elem="AdditionalContent">
                                {children}
                            </div>
                        </div>
                        {this.renderProductActions()}
                    </>
                )}
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </>
        );
    }

    renderRatingSummary() {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} showCounter={false} />;
    }

    render() {
        const { children, mix, isLoading, layout, sliderMode } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li block="ProductCard" mods={{ layout }} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={{ layout, isSlider: sliderMode }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default withRouter(ProductCard);
