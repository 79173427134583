import { MyAccountAddressTable as SourceMyAccountAddressTable } from 'AdvoxComponent/MyAccountAddressTable/MyAccountAddressTable.component';
import Button from 'Component/Button';
import Icons from 'Component/Icons';

import './MyAccountAddressTable.style';

/** @namespace Pwabb/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTable extends SourceMyAccountAddressTable {
    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address: { default_billing, default_shipping },
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <Button onClick={onEditClick}>{__('Edit address')}</Button>
                <Button
                    onClick={onDeleteClick}
                    variant="likeLink"
                    disabled={isDeleteAllowed}
                    title={isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address'}
                    iconBefore={<Icons name="trash" fill="#797979" width="16px" />}
                >
                    {__('Delete address')}
                </Button>
            </>
        );
    }
}

export default MyAccountAddressTable;
