import { SHOW_RETAIL_PRICES_TOGGLE } from './ShowRetailPrices.action';

/** @namespace Pwabb/Store/ShowRetailPrices/Reducer/getLocalStorageItem */
export const getLocalStorageItem = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);

    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
};

export const initialState = {
    showPrices: getLocalStorageItem('showPrices', false),
};

/** @namespace Pwabb/Store/ShowRetailPrices/Reducer/ShowRetailPricesReducer */
export const ShowRetailPricesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_RETAIL_PRICES_TOGGLE:
            const newShowPrices = !state.showPrices;

            localStorage.setItem('showPrices', newShowPrices);

            return {
                ...state,
                showPrices: newShowPrices,
            };
        default:
            return state;
    }
};

export default ShowRetailPricesReducer;
