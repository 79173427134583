import { PureComponent } from 'react';

import Icons from 'Component/Icons';

import { ANNOUNCEMENT_KEY } from './Announcement.config';

import './AnnouncementNotice.style';

/** @namespace Pwabb/Component/AnnouncementNotice/Component */
export class AnnouncementNoticeComponent extends PureComponent {
    renderContent() {
        const { announcement } = this.props;
        const { background_color, content, can_close, id } = announcement || {};

        if (!content) {
            return null;
        }

        return (
            <div
                id={`${ANNOUNCEMENT_KEY}_${id}`}
                block="AnnouncementNotice"
                style={{ backgroundColor: background_color, noClose: !can_close }}
            >
                {this.renderHtmlContent()}
            </div>
        );
    }

    renderHtmlContent() {
        const { announcement } = this.props;
        const { content, text_color } = announcement || {};

        return (
            <div block="AnnouncementNotice" elem="Content" style={{ color: text_color }}>
                <div block="AnnouncementNotice" elem="Text" dangerouslySetInnerHTML={{ __html: content }} />
                {this.renderHideNotice()}
            </div>
        );
    }

    renderHideNotice() {
        const { announcement, hideAnnouncementNotice } = this.props;
        const { can_close, text_color, id } = announcement || {};

        if (!can_close) {
            return null;
        }

        return (
            <button
                block="AnnouncementNotice"
                elem="CloseIcon"
                onClick={() => {
                    hideAnnouncementNotice(id);
                }}
            >
                <Icons name="close" fill={text_color || '#45062e'} />
            </button>
        );
    }

    render() {
        return this.renderContent();
    }
}

export default AnnouncementNoticeComponent;
