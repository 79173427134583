export * from 'SourceType/Account.type';

export const LOYALTY_PROGRAM = 'loyalty-program';
export const ACCOUNT_DATA = 'account-data';
export const CONTRACTOR_USER_PAGE = 'contractor-user-page';
export const CONTRACTOR_USER_ACTIONS = {
    list: 'list',
    create: 'create',
    edit: 'edit',
};
export const FINANCIAL_DOCUMENT = 'financial-document';
export const COMPANY_STRUCTURE = 'company-structure';
