import { scrollToTop, toggleScroll } from 'SourceUtil/Browser/Browser';

export * from 'SourceUtil/Browser/Browser';

/** @namespace Pwabb/Util/Browser/unfreezeScroll */
export const unfreezeScroll = () => {
    toggleScroll(true);
    document.body.style.marginTop = 0;
    scrollToTop();
};
