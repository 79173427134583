import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

export { mapDispatchToProps };

/** @namespace Pwabb/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    showOnlyRetailPrices: state.ShowRetailPrices.showPrices,
});

/** @namespace Pwabb/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps() {
        const { isPDP, showOnlyRetailPrices, isComparePage } = this.props;

        return {
            ...super.containerProps(),
            isPDP,
            showOnlyRetailPrices,
            isComparePage,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
