import { AddToCart as AdvoxAddToCart } from 'AdvoxComponent/AddToCart/AddToCart.component';
import Icons from 'Component/Icons';
import { isSignedIn } from 'Util/Auth';

/** @namespace Pwabb/Component/AddToCart/Component */
export class AddToCart extends AdvoxAddToCart {
    renderCartIcon() {
        const { isIconEnabled } = this.props;

        if (!isIconEnabled) {
            return null;
        }

        return <Icons name="cart" />;
    }

    renderText() {
        const { isAdding, isIconEnabled } = this.props;

        if (isIconEnabled) {
            return null;
        }

        return <span>{isAdding ? __('Adding...') : __('Add to cart')}</span>;
    }

    render() {
        const { mix, addProductToCart, layout, isDisabled, isAdding } = this.props;

        return (
            <button
                onClick={addProductToCart}
                block="Button AddToCart"
                mix={mix}
                mods={{ layout }}
                disabled={!isSignedIn() || isDisabled || isAdding}
            >
                {this.renderCartIcon()}
                {this.renderText()}
            </button>
        );
    }
}

export default AddToCart;
