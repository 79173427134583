import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';

/** @namespace Pwabb/Component/Meta/Component */
export class Meta extends SourceMeta {
    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                {this.renderTitle()}
                {this.renderCanonical()}
                {metadata.map((tag) => {
                    const { name = null, property = null, content = null } = tag;

                    return <meta key={name || property} property={property} name={name} content={content} />;
                })}
            </>
        );
    }
}

export default Meta;
