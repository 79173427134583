import { ProductListQuery as AdvoxProductListQuery } from 'AdvoxQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Pwabb/Query/ProductList/Query */
export class ProductListQuery extends AdvoxProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);

        fields.push(
            this._getRelatedProductsByAttribute(),
            ...this._getRelatedProductsByAttributeLabel(),
            this._getProductAttachments(),
            this._getStockSourcesField(),
            'availabilityMessage'
        );

        return fields;
    }

    _getRelatedProductsByAttribute() {
        return new Field('related_products_by_attribute').addFieldList([
            'url_key',
            'name',
            'related_product_label',
            this._getProductImageField(),
            this._getProductSmallField(),
        ]);
    }

    _getRelatedProductsByAttributeLabel() {
        return ['related_product_attribute', 'related_products_by_attribute_label'];
    }

    _getProductAttachments() {
        return new Field('attachments').addFieldList(['name', 'description', 'url']);
    }

    _getCartProductInterfaceFields() {
        return [...super._getCartProductInterfaceFields(), this._getPriceRangeField(), this._getStockSourcesField()];
    }

    _getCustomFilters(filters = {}) {
        return Object.entries(filters).reduce((acc, [key, attribute]) => {
            if (!attribute.length) {
                return acc;
            }

            if (key === 'price' || key === 'sliderfilterprice') {
                return {
                    ...acc,
                    ...this._getPriceFilter(key, attribute),
                };
            }

            if (key === 'stock_status') {
                return {
                    ...acc,
                    [key]: { eq: attribute[0] },
                };
            }

            return {
                ...acc,
                [key]: { in: attribute },
            };
        }, {});
    }

    _getStockItemFields() {
        return ['backorders', 'in_stock', 'min_sale_qty', 'max_sale_qty', 'qty_increments'];
    }

    _getStockSourcesField() {
        return new Field('stock_sources').addFieldList(this._getStockSourcesFields());
    }

    _getStockSourcesFields() {
        return ['source_code', 'quantity'];
    }

    _getCartProductField() {
        return new Field('product').addFieldList([
            'id',
            'sku',
            'stock_status',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getAttributesField(true, true),
            this._getPriceRangeField(),
        ]);
    }
}

export default new ProductListQuery();
