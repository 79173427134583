import { CatalogWidget as AdvoxCatalogWidget } from 'AdvoxQuery/CatalogWidget.query';
import ProductListQuery from 'Query/ProductList.query';

/** @namespace Pwabb/Query/CatalogWidget/Query */
export class CatalogWidget extends AdvoxCatalogWidget {
    _getCatalogWidgetFields() {
        return ['title', 'widget_code', ProductListQuery._getItemsField()];
    }
}

export default new CatalogWidget();
