import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    IconsContainer as SourceIconsContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'AdvoxComponent/Icons/Icons.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwabb/Component/Icons/Container */
export class IconsContainer extends SourceIconsContainer {
    static propTypes = {
        ...SourceIconsContainer.propTypes,
        name: PropTypes.oneOf([
            'magnifier',
            'user',
            'bag',
            'compare',
            'dropdown_arrow',
            'dropdown_arrow_up',
            'close',
            'arrow_left',
            'arrow_right',
            'arrow_up',
            'arrow_down',
            'phone',
            'envelop',
            'clock',
            'youtube',
            'twitter',
            'facebook',
            'pinterest',
            'instagram',
            'file_download',
            'eye_crossed',
            'eye',
            'calendar',
            'badge',
            'hand',
            'chat',
            'car',
            'heart',
            'pin',
            'logo_small',
            'trash',
            'plus',
            'time',
            'pencil',
            'truck',
            'envelope',
            'clock_2',
            'truck_2',
            'phone_2',
            'box',
            'angle_right',
            'freeshipping',
            'edit',
            'flatrate',
            'next',
            'warning',
            'check_ok',
            'error',
            'cart',
            'account_dashboard',
            'account_data',
            'account_orders',
            'account_structure',
            'account_users',
            'account_invoices',
            'account_logout',
            'reorder',
            'date_picker',
            'warning_fill',
            'file_pdf_download',
        ]).isRequired,
    };

    static defaultProps = {
        ...SourceIconsContainer.defaultProps,
        block: 'Icon',
        fill: 'currentColor',
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IconsContainer);
