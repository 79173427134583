import FIELD_TYPE from 'Component/Field/Field.config';

/** @namespace Pwabb/Component/MyAccountCustomerForm/Form/myAccountCustomerForm */
export const myAccountCustomerForm = (props) => {
    const { firstname, lastname } = props;

    return [
        {
            type: FIELD_TYPE.text,
            label: __('First name'),
            attr: {
                name: 'firstname',
                defaultValue: firstname,
                placeholder: __('Your first name'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Last name'),
            attr: {
                name: 'lastname',
                defaultValue: lastname,
                placeholder: __('Your last name'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
    ];
};
export default myAccountCustomerForm;
