export * from 'AdvoxUtil/Address';

/** @namespace Pwabb/Util/Address/Index/getCheckoutDefaultAddressLabel */
export const getCheckoutDefaultAddressLabel = (address, isShipping) => {
    const { default_billing, default_shipping } = address;

    if (default_shipping && isShipping) {
        return __(' (default shipping address)');
    }

    if (default_billing && !isShipping) {
        return __(' (default billing address)');
    }

    return '';
};

/** @namespace Pwabb/Util/Address/Index/getBillingAddress */
export const getBillingAddress = (contractorData) => {
    const {
        company,
        vat_id,
        region,
        city,
        country,
        postcode,
        phone,
        street,
        main_customer: { firstname, lastname },
    } = contractorData;

    const billingAddress = {
        company,
        vat_id,
        country_id: country,
        region,
        city,
        postcode,
        telephone: phone,
        street,
        firstname,
        lastname,
    };

    return billingAddress;
};
