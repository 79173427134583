import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    CONSENTS_CHECKOUT,
    CONSENTS_CONTACT,
    CONSENTS_NEWSLETTER,
    CONSENTS_REGISTER,
} from 'Component/Consents/Consents.config';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import ConsentsQuery from 'Query/Consents.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery, getErrorMessage } from 'Util/Request';
import getStore from 'Util/Store';

import Consents from './Consents.component';

/** @namespace Pwabb/Component/Consents/Container */
export class ConsentsContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([CONSENTS_REGISTER, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_CHECKOUT]),
        data: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                is_required: PropTypes.bool.isRequired,
                is_checkbox: PropTypes.bool.isRequired,
            })
        ),
        currentPlace: PropTypes.string,
        validateConsents: PropTypes.bool,
        requireInfo: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        data: null,
        validateConsents: false,
        requireInfo: true,
        onChange: () => {},
    };

    static getConsentsByType(type) {
        if (!type) throw new Error('Consents type is required');

        return fetchQuery(ConsentsQuery.getAdvoxConsentsQuery(type))
            .then(
                /** @namespace Pwabb/Component/Consents/Container/ConsentsContainer/getConsentsByType/then/catch/fetchQuery/then */
                ({ getConsents }) => getConsents
            )
            .catch(
                /** @namespace Pwabb/Component/Consents/Container/ConsentsContainer/getConsentsByType/then/catch */
                (error) => {
                    getStore().dispatch(showNotification('error', getErrorMessage(error), error));
                }
            );
    }

    static getAcceptedConsents({ fields = {}, consents = [] } = {}) {
        const checkbox = fields
            .filter((field) => field.type === FIELD_TYPE.checkbox && field.value === true)
            .map((consent) => consent.name);
        const text = consents.filter((consent) => !consent.is_checkbox).map((consent) => consent.consent_id);

        return [...checkbox, ...text];
    }

    state = {
        data: [],
        selectedConsentIds: [],
        requiredContentIds: [],
        areAllRequiredContentsAccepted: false,
    };

    containerFunctions = {
        updateSelectedConsentIds: this.updateSelectedConsentIds.bind(this),
    };

    componentDidMount() {
        this.getContentsData();
    }

    containerProps() {
        const { data } = this.state;
        const { currentPlace, validateConsents, requireInfo, onChange } = this.props;

        return {
            data,
            currentPlace,
            validateConsents,
            requireInfo,
            onChange,
        };
    }

    async getContentsData() {
        const { data, type } = this.props;

        if (data) {
            this.setState((prevState) => ({
                ...prevState,
                data,
            }));
        } else if (!data && type) {
            await ConsentsContainer.getConsentsByType(type).then(
                /** @namespace Pwabb/Component/Consents/Container/ConsentsContainer/getContentsData/getConsentsByType/then */
                (response) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        data: response,
                    }));

                    this.getRequiredContents();
                }
            );
        }
    }

    getRequiredContents() {
        const { data } = this.state;
        const requiredContentIds = data.filter(({ is_required }) => is_required).map(({ consent_id }) => consent_id);

        this.setState({
            requiredContentIds,
            areAllRequiredContentsAccepted: requiredContentIds.length <= 0,
        });
    }

    checkTermsAndConditions = () => {
        const { requiredContentIds, selectedConsentIds } = this.state;
        const requiredConsentsSelected = requiredContentIds.every((elem) => selectedConsentIds.indexOf(elem) > -1);

        this.setState({
            areAllRequiredContentsAccepted: requiredConsentsSelected,
        });
    };

    updateSelectedConsentIds(consentId, event) {
        const { checked } = event.target;
        const { selectedConsentIds } = this.state;
        const { currentPlace } = this.props;

        const currentConsentId = !currentPlace ? consentId : consentId.split(currentPlace).pop();

        const contents = checked
            ? selectedConsentIds.concat(currentConsentId)
            : selectedConsentIds.filter((val) => val !== currentConsentId);

        this.setState(
            {
                selectedConsentIds: contents,
            },
            () => this.checkTermsAndConditions()
        );
    }

    render() {
        return <Consents {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default ConsentsContainer;
