import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import getStore from 'Util/Store';

/** @namespace AltomAdvoxbb/Permissions/Component/Check/Component */
export class Check extends PureComponent {
    static propTypes = {
        authorities: PropTypes.arrayOf(PropTypes.string),
        permissions: PropTypes.arrayOf(PropTypes.string),
        fallback: PropTypes.node,
    };

    static defaultProps = {
        authorities: [],
        permissions: [],
        fallback: null,
    };

    static permissions(permissions, authorities = getStore().getState().MyAccountReducer?.authorities ?? []) {
        if (isEmpty(permissions) || indexOf(authorities, 'all') !== -1) {
            return true;
        }

        for (let i = 0; i < authorities.length; i += 1) {
            if (indexOf(permissions, authorities[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { permissions, authorities, fallback, children } = this.props;

        if (!Check.permissions(permissions, authorities)) {
            if (fallback) {
                return fallback;
            }

            return null;
        }

        return children;
    }
}

export default Check;
