import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import AnnouncementQuery from '../../query/Announcement.query';
import { setAnnouncement } from './Announcement.action';

/** @namespace Pwabb/Store/Announcement/Dispatcher */
export class AnnouncementDispatcher {
    getAnnouncement(options, dispatch) {
        // this.getCurrentLocation(location);
        return executeGet(prepareQuery(AnnouncementQuery.getAnnouncementQuery(options, dispatch), true)).then(
            /** @namespace Pwabb/Store/Announcement/Dispatcher/AnnouncementDispatcher/getAnnouncement/executeGet/then */
            (response) => {
                dispatch(setAnnouncement(response?.announcement));
            },
            /** @namespace Pwabb/Store/Announcement/Dispatcher/AnnouncementDispatcher/getAnnouncement/executeGet/then/catch */
            () => {}
        );
    }
}

export default new AnnouncementDispatcher();
