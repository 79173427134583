import { Html as SourceHtml, WidgetFactory } from 'AdvoxComponent/Html/Html.component';
import AnnouncementNotice from 'Component/AnnouncementNotice';

export { WidgetFactory };

/** @namespace Pwabb/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        ...this.rules,
        {
            query: { attribs: ['data-announcement'] },
            replace: this.replaceAnnouncement,
        },
    ];

    replaceAnnouncement({ attribs }) {
        const { 'data-announcement': canShow } = this.attributesToProps(attribs);

        return canShow ? <AnnouncementNotice /> : null;
    }
}

export default Html;
