import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AnnouncementDispatcher from 'Store/Announcement/Announcement.dispatcher';
import { HistoryType, LocationType, MatchType } from 'Type/Router.type';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { ANNOUNCEMENT_KEY, CART_PATH, CHECKOUT_PATH, SEARCH_PATH } from './Announcement.config';
import AnnouncementNotice from './AnnouncementNotice.component';

/** @namespace Pwabb/Component/AnnouncementNotice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getAnnouncement: (options) => AnnouncementDispatcher.getAnnouncement(options, dispatch),
});

/** @namespace Pwabb/Component/AnnouncementNotice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    announcement: state.AnnouncementReducer.announcement,
    currentStoreCode: state.ConfigReducer.code,
    storeId: state.ConfigReducer.id,
    isMobile: state.ConfigReducer.device.isMobile,
    isTablet: state.ConfigReducer.device.isTablet,
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
});

/** @namespace Pwabb/Component/AnnouncementNotice/Container */
export class AnnouncementNoticeContainer extends PureComponent {
    static propTypes = {
        getAnnouncement: PropTypes.func,
        announcement: PropTypes.shape({
            content: PropTypes.string,
            display_from: PropTypes.string,
            display_to: PropTypes.string,
            places: PropTypes.string,
        }),
        location: LocationType,
        history: HistoryType,
        match: MatchType,
    };

    static defaultProps = {
        getAnnouncement: () => {},
        announcement: null,
        location: null,
    };

    containerFunctions = {
        hideAnnouncementNotice: this.hideAnnouncementNotice.bind(this),
    };

    componentDidMount() {
        this.loadAnnouncement();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            urlRewrite,
        } = this.props;
        const {
            location: { pathname: prevPathName },
            urlRewrite: prevUrlRewrite,
        } = prevProps;

        if (prevPathName !== pathname || prevUrlRewrite !== urlRewrite) {
            this.loadAnnouncement();
        }
    }

    getCurrentPlace() {
        const {
            urlRewrite: { type } = {},
            history: {
                location: { pathname },
            },
        } = this.props;

        if (pathname) {
            if (pathname === '/') return 'cms';
            if (pathname.includes(CART_PATH)) return 'cart';
            if (pathname.includes(SEARCH_PATH)) return 'search';
            if (pathname.includes(CHECKOUT_PATH)) return 'checkout';
        }

        if (type) {
            return type.toLowerCase();
        }

        return null;
    }

    async loadAnnouncement() {
        const { storeId: store, getAnnouncement } = this.props;
        const {
            location: { href },
        } = window;

        const place = this.getCurrentPlace();
        const now = new Date();
        const dateString = now.toISOString().split('T')[0];
        const fullDateString = `${dateString} ${now.getHours()}:${now.getMinutes()}:00`;

        if (place) {
            await getAnnouncement({
                place,
                store,
                url: href,
                date: fullDateString,
            });
        }
    }

    hideAnnouncementNotice(id) {
        const key = `${ANNOUNCEMENT_KEY}_${id}`;
        BrowserDatabase.setItem(true, key, ONE_MONTH_IN_SECONDS);
        const announcementDOM = document.getElementById(key);

        if (announcementDOM) {
            announcementDOM.style.display = 'none';
            document.documentElement.style.setProperty('--demo-notice-height', '0px');
        }
    }

    checkAnnouncement(announcement) {
        const { isMobile, isTablet } = this.props;
        const { content, id } = announcement || {};

        if (!content || (id && BrowserDatabase.getItem(`${ANNOUNCEMENT_KEY}_${id}`))) {
            document.documentElement.style.setProperty('--demo-notice-height', '0px');

            return null;
        }

        if (announcement) {
            document.documentElement.style.setProperty('--demo-notice-height', isMobile || isTablet ? '60px' : '40px');
        }

        return announcement;
    }

    containerProps() {
        const { announcement } = this.props;

        return {
            announcement: this.checkAnnouncement(announcement),
        };
    }

    render() {
        return <AnnouncementNotice {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnouncementNoticeContainer));
