import PRODUCT_TYPE from 'Component/Product/Product.config';
import { TierPrices as SourceTierPrices } from 'SourceComponent/TierPrices/TierPrices.component';
import { calculateTierDiscountOverSpecialPrice, formatPrice } from 'Util/Price';

import './TierPrices.override.style';

/** @namespace Pwabb/Component/TierPrices/Component */
export class TierPrices extends SourceTierPrices {
    renderDetailedTierPrice({ discount: { percent_off: tierDiscount }, final_price: { value, currency }, quantity }) {
        const {
            product: {
                price_range: {
                    minimum_price: {
                        final_price_excl_tax: { value: minPriceForOneUnit },
                        discount: { percent_off: discountForOneUnit },
                    },
                },
                type_id,
            },
        } = this.props;

        // Don't show offers that make no sense
        if (value >= minPriceForOneUnit) {
            return null;
        }

        // If the product already has Special price, calculate % off over special price and not old price
        // Bundle product always shows discount over old price
        const percentOff =
            discountForOneUnit > 0 ? calculateTierDiscountOverSpecialPrice(minPriceForOneUnit, value) : tierDiscount;

        const formattedPrice = formatPrice(value, currency);

        return (
            <li block="TierPrices" elem="Item" key={quantity}>
                {type_id === PRODUCT_TYPE.bundle
                    ? this.renderBundleTierPrice(quantity, tierDiscount)
                    : this.renderProductTierPrice(quantity, formattedPrice, percentOff)}
            </li>
        );
    }
}

export default TierPrices;
