import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as advoxMapStateToProps,
    MyAccountCreateAccountContainer as AdvoxMyAccountCreateAccountContainer,
} from 'AdvoxComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import CompanyDataQuery from 'Query/CompanyData.query';
import ContractorQuery from 'Query/Contractor.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { CountriesType } from 'Type/Config.type';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation, fetchQuery } from 'Util/Request';
import transformCountriesToOptions from 'Util/Store/Transform';

/** @namespace Pwabb/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...advoxMapStateToProps(state),
    countries: transformCountriesToOptions(state.ConfigReducer.countries || []),
    defaultCountry: state.ConfigReducer.default_country,
});

/** @namespace Pwabb/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends AdvoxMyAccountCreateAccountContainer {
    static propTypes = {
        ...this.propTypes,
        countries: CountriesType.isRequired,
    };

    state = {
        ...this.state,
        consentsNewsletterValidate: false,
        isCheckingNip: false,
        useFieldMask: true,
        countryId: this.getCountry()?.value || 'US',
    };

    getCountry(countryId = null) {
        const { countries, defaultCountry } = this.props;
        const countryIdFixed = countryId || defaultCountry;
        return countries.find(({ value }) => value === countryIdFixed);
    }

    // inject values from gusData object into form fields
    injectValuesIntoForm(gusData) {
        if (gusData && Object.keys(gusData).length) {
            Object.keys(gusData).forEach((key) => {
                const field = document.getElementById(key);

                if (field) {
                    field.value = gusData[key];
                }
            });
        }
    }

    consentsNewsletterRef = createRef();

    containerFunctions = {
        ...this.containerFunctions,
        getDataByNip: this.getDataByNip.bind(this),
    };

    async getDataByNip(e) {
        const nip = e.target.value.replaceAll(' ', '');
        this.setState({ isCheckingNip: true });

        await fetchQuery(CompanyDataQuery.getCompanyDataByNip(nip)).then(
            /** @namespace Pwabb/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/getDataByNip/fetchQuery/then */
            ({ gusDataByNip }) => {
                this.setState({ isCheckingNip: false });
                this.injectValuesIntoForm(gusDataByNip);
            },
            /** @namespace Pwabb/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/getDataByNip/fetchQuery/then/catch */
            () => {
                this.setState({ isCheckingNip: false });
            }
        );
    }

    validNewsletterConsents(allRequiredConsetsSelected) {
        this.setState((prevState) => ({
            ...prevState,
            consentsNewsletterValidate: !allRequiredConsetsSelected,
        }));
    }

    containerProps() {
        const { isCheckingNip, companyData, countryId, consentsNewsletterValidate, useFieldMask } = this.state;
        const { countries } = this.props;

        return {
            ...super.containerProps(),
            consentsNewsletterValidate,
            consentsNewsletterRef: this.consentsNewsletterRef,
            countryId,
            countries,
            companyData,
            isCheckingNip,
            useFieldMask,
        };
    }

    showErrorMessage() {
        showNotification('error', __('Error occured. Please, try again later'));
    }

    async onSuccess(form, fields) {
        const { setLoadingState, isLoading, showNotification, newsletterActive } = this.props;

        const {
            email,
            apartment_number,
            building_number,
            city,
            company_name,
            first_name,
            last_name,
            mobile_phone_number,
            notes,
            phone_number,
            post_code,
            street,
            tax_number,
            country_id,
            cooperation_type,
        } = transformToNameValuePair(fields);

        const consentsNewsletter = newsletterActive ? this.consentsNewsletterRef.current.state : {};

        const { selectedConsentIds: selectedNewsletterConsentIds = [] } = consentsNewsletter || {};

        const formData = {
            email,
            apartment_number,
            building_number,
            city,
            company_name,
            first_name,
            last_name,
            mobile_phone_number,
            notes,
            phone_number,
            post_code,
            street,
            tax_number,
            is_regulations_confirmed: true,
            country: this.getCountry(country_id)?.value,
            cooperation_type,
            is_subscribed: (selectedNewsletterConsentIds ?? []).length > 0,
        };

        if (isLoading) {
            return;
        }

        setLoadingState(true);

        this.setState({ useFieldMask: false });

        try {
            await fetchMutation(ContractorQuery.getCreateContractorMutation(formData)).then(
                /** @namespace Pwabb/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/fetchMutation/then */
                ({ createCustomerContactForm: { customerContactFormId } }) => {
                    if (customerContactFormId && customerContactFormId !== '') {
                        showNotification(
                            'success',
                            // eslint-disable-next-line max-len
                            __('Request has been sent. Please be patient, we will contact you soon.')
                        );
                    } else {
                        this.showErrorMessage();
                    }

                    if (typeof form.reset === 'function') {
                        form.reset();
                    }
                },
                /** @namespace Pwabb/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/fetchMutation/then/catch */
                this.showErrorMessage
            );
        } finally {
            setLoadingState(false);
            this.setState({ useFieldMask: true });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
