import { PERMISSIONS } from '@sevenanna-advox-bb/permissions/src/component/Check/Check.config';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountContainer as AdvoxMyAccountContainer,
} from 'AdvoxRoute/MyAccount/MyAccount.container';
import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';
import { ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    ACCOUNT_DATA,
    COMPANY_STRUCTURE,
    CONTRACTOR_USER_PAGE,
    FINANCIAL_DOCUMENT,
    MY_ACCOUNT,
    MY_ORDERS,
    MY_WISHLIST,
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import { scrollToTop } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import MyAccount from './MyAccount.component';

export * from 'AdvoxRoute/MyAccount/MyAccount.container';

/** @namespace Pwabb/Route/MyAccount/Container */
export class MyAccountContainer extends AdvoxMyAccountContainer {
    static tabMap = {
        [MY_ACCOUNT]: {
            url: '',
            tabName: __('Dashboard'),
            iconName: 'account_dashboard',
        },
        [ACCOUNT_DATA]: {
            url: '/customer/account-data',
            tabName: __('Account data'),
            isFullUrl: true,
            iconName: 'account_data',
        },
        [MY_ORDERS]: {
            url: '/sales/order/history',
            tabName: __('Orders'),
            isFullUrl: true,
            iconName: 'account_orders',
        },
        [COMPANY_STRUCTURE]: {
            url: '/company/structure',
            tabName: __('Company structure'),
            isFullUrl: true,
            iconName: 'account_structure',
        },
        [CONTRACTOR_USER_PAGE]: {
            url: '/contractor/user/list',
            tabName: __('Users'),
            iconName: 'account_users',
            hiddenHeader: true,
            isFullUrl: true,
            permissions: PERMISSIONS.SHOW_USERS,
        },
        [FINANCIAL_DOCUMENT]: {
            url: '/financial/document/history',
            tabName: __('Settlements'),
            isFullUrl: true,
            iconName: 'account_invoices',
        },
        [MY_WISHLIST]: {
            url: '/wishlist',
            tabName: __('Wishlist'),
            isFullUrl: true,
            iconName: 'heart',
        },
    };

    static navigateToSelectedTab(props, state = {}) {
        const { match: { params: { tab: historyActiveTab } = {} } = {}, isMobile, selectedTab } = props;
        const { activeTab } = state;

        if (this.tabMap[selectedTab] && isSignedIn()) {
            return { activeTab: selectedTab };
        }

        // redirect to Dashboard, if user visited non-existent or disabled page
        const newActiveTab =
            this.tabMap[historyActiveTab] && MyAccountContainer.isTabEnabled(props, historyActiveTab)
                ? historyActiveTab
                : MY_ACCOUNT;
        const { url: activeTabUrl } = this.tabMap[newActiveTab];

        if (historyActiveTab !== newActiveTab && activeTab !== MY_ACCOUNT && isSignedIn() && !isMobile) {
            history.push(appendWithStoreCode(`${ACCOUNT_URL}${activeTabUrl}`));
        }

        if (activeTab !== newActiveTab) {
            return { activeTab: newActiveTab };
        }

        return null;
    }

    static getDerivedStateFromProps(props, state) {
        return MyAccountContainer.navigateToSelectedTab(props, state);
    }

    __construct() {
        const { updateMeta, toggleOverlayByKey } = this.props;

        this.state = {
            ...MyAccountContainer.navigateToSelectedTab(this.props),
            isEditingActive: false,
            tabName: '',
            stateSubHeading: '',
        };

        if (!isSignedIn()) {
            toggleOverlayByKey(CUSTOMER_ACCOUNT);
        }

        updateMeta({ title: __('My account') });

        this.redirectIfNotSignedIn();
        this.onSignIn();
        this.updateBreadcrumbs();
        scrollToTop();
    }

    getTabName() {
        const {
            location: { pathname },
        } = this.props;
        const { tabName: stateTabName, activeTab } = this.state;
        const { tabName, url } = MyAccountContainer.tabMap[activeTab];

        if (!pathname.includes(url)) {
            return stateTabName;
        }

        return tabName;
    }

    tabsFilterEnabled() {
        return Object.entries(MyAccountContainer.tabMap).reduce(
            (enabledTabs, [key, value]) =>
                MyAccountContainer.isTabEnabled(this.props, key) ? { ...enabledTabs, [key]: value } : enabledTabs,
            {}
        );
    }

    changeActiveTab(activeTab) {
        const {
            [activeTab]: { url, isFullUrl = false },
        } = this.tabsFilterEnabled(MyAccountContainer.tabMap);

        if (isFullUrl) {
            history.push(appendWithStoreCode(url));
        } else {
            history.push(appendWithStoreCode(`${ACCOUNT_URL}${url}`));
        }

        this.changeMyAccountHeaderState();
    }

    handleCheckIfSelectedTab() {
        const {
            selectedTab,
            location: { pathname = '' },
        } = this.props;

        if (selectedTab) {
            return true;
        }

        return Object.values(MyAccountContainer.tabMap).find(({ url }) => pathname.includes(url));
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const { activeTab } = this.state;
        const { url, tabName, breadcrumb, isFullUrl } = MyAccountContainer.tabMap[activeTab];
        const breadcrumbs = [];

        breadcrumbs.push({
            url: isFullUrl ? url : `${ACCOUNT_URL}${url}`,
            name: breadcrumb ?? tabName,
        });

        breadcrumbs.push({ name: __('My Account'), url: ACCOUNT_URL });

        updateBreadcrumbs(breadcrumbs);
    }

    onSignIn() {
        this.changeMyAccountHeaderState();
    }

    render() {
        return (
            <MyAccount
                {...this.containerProps()}
                {...this.containerFunctions}
                tabMap={this.tabsFilterEnabled(MyAccountContainer.tabMap)}
            />
        );
    }
}

export default withReducers({
    OrderReducer,
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer));
