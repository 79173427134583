import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import ImageLazyLoading from 'Component/ImageLazyLoading';
import Link from 'Component/Link';

import productPlaceholderSmall from '../../assets/images/productPlaceholderSmallRelated.png';

import './RelatedProductsByAttribute.style';

/** @namespace Pwabb/Component/RelatedProductsByAttribute/Component */
export class RelatedProductsByAttribute extends PureComponent {
    static propTypes = {
        productUrlSuffix: PropTypes.string,
        label: PropTypes.string,
        products: PropTypes.array,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        variant: PropTypes.string,
    };

    static defaultProps = {
        productUrlSuffix: '',
        label: '',
        products: [],
        onMouseEnter: () => {},
        onMouseLeave: () => {},
        variant: '',
    };

    render() {
        const { products, productUrlSuffix, label, onMouseEnter, onMouseLeave, variant } = this.props;

        return products ? (
            <div block="RelatedProductsByAttribute">
                {label && products.length ? (
                    <div block="RelatedProductsByAttribute" elem="Label" mods={{ variant }}>
                        {label}
                    </div>
                ) : null}
                <div block="RelatedProductsByAttribute" elem="RelatedProducts" mods={{ variant }}>
                    {products.map(({ url_key, name, image, small_image, related_product_label }, index) => (
                        <Link
                            to={`${url_key}${productUrlSuffix}`}
                            block="RelatedProductsByAttribute"
                            elem="RelatedProduct"
                            key={`relatedproduct-${index}`}
                            onMouseEnter={() => onMouseEnter(image && image.url)}
                            onMouseLeave={() => onMouseLeave()}
                            title={related_product_label}
                        >
                            <ImageLazyLoading
                                src={small_image && small_image.url ? small_image.url : productPlaceholderSmall}
                                alt={name}
                                lazyLoadingType="productTileRelated"
                            />
                        </Link>
                    ))}
                </div>
            </div>
        ) : null;
    }
}

export default withRouter(RelatedProductsByAttribute);
