import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Pwabb/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getUpdateNewsletterMutation(options) {
        return new Field('updateCustomer')
            .addArgument('input', 'CustomerInput!', options)
            .addField(this._getCustomerField());
    }

    _getAddressFields() {
        return [...super._getAddressFields(), 'company'];
    }

    getCreateAccountMutation(options) {
        const { customer } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer })
            .addField(this._getCustomerField());
    }
}

export default new MyAccountQuery();
