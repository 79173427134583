import { MyAccountForgotPassword as AdvoxMyAccountForgotPassword } from 'AdvoxComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Pwabb/Component/MyAccountForgotPassword/Component */
export class MyAccountForgotPassword extends AdvoxMyAccountForgotPassword {
    renderForgotPasswordForm() {
        const { onForgotPasswordSuccess, onFormError } = this.props;

        return (
            <Form key="forgot-password" onSubmit={onForgotPasswordSuccess} onError={onFormError}>
                <Field
                    type={FIELD_TYPE.email}
                    label={__('Type your e-mail address')}
                    attr={{
                        id: 'email',
                        name: 'email',
                        class: 'ForgotPassword-Input_type_email',
                        placeholder: __('Your email address'),
                        autocomplete: 'email',
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                        inputType: VALIDATION_INPUT_TYPE.email,
                    }}
                    addRequiredTag
                />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                        block="Button"
                        mods={{ size: 'medium' }}
                        type="submit"
                        mix={{ block: 'MyAccountOverlay', elem: 'ResetPassword' }}
                    >
                        {__('Send link')}
                    </button>
                </div>
            </Form>
        );
    }

    renderAdditionalField() {
        const { handleSignIn } = this.props;

        return (
            <article block="MyAccountOverlay" elem="Additional">
                <section aria-labelledby="forgot-password-label">
                    <span block="MyAccountOverlay" elem="AdditionalLabel">
                        {__('Remember password?')}
                    </span>
                    <button block="Button" mods={{ likeLink: true }} onClick={handleSignIn}>
                        {__('Sign In')}
                    </button>
                </section>
            </article>
        );
    }
}

export default MyAccountForgotPassword;
