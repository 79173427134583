import {
    RESET_CONTRACTOR_DATA,
    UPDATE_CONTRACTOR_CURRENT_CONTRACTOR,
    UPDATE_CONTRACTOR_CURRENT_USER,
    UPDATE_CONTRACTOR_STRUCTURE,
    UPDATE_CONTRACTOR_SUB_STRUCTURE,
    UPDATE_CONTRACTOR_USER_ROLE_LIST,
} from './Contractor.actions';

/** @namespace Pwabb/Store/Contractor/Reducer/getInitialState */
export const getInitialState = () => ({
    user: {},
    structure: {},
    contractor: {},
    availableRoles: [],
    subStructure: {},
});

/** @namespace Pwabb/Store/Contractor/Reducer/ContractorReducer */
export const ContractorReducer = (state = getInitialState(), action) => {
    const { type, user, structure, contractor, availableRoles, subStructure } = action;

    switch (type) {
        case UPDATE_CONTRACTOR_CURRENT_CONTRACTOR: {
            return {
                ...state,
                contractor,
            };
        }
        case UPDATE_CONTRACTOR_CURRENT_USER: {
            return {
                ...state,
                user,
            };
        }
        case UPDATE_CONTRACTOR_STRUCTURE: {
            return {
                ...state,
                structure,
            };
        }
        case UPDATE_CONTRACTOR_USER_ROLE_LIST: {
            return {
                ...state,
                availableRoles,
            };
        }
        case UPDATE_CONTRACTOR_SUB_STRUCTURE: {
            return {
                ...state,
                subStructure,
            };
        }
        case RESET_CONTRACTOR_DATA: {
            return getInitialState();
        }
        default:
            return state;
    }
};

export default ContractorReducer;
