import { Field } from 'Util/Query';

/** @namespace Pwabb/Query/Contractor/Query */
export class ContractorQuery {
    getCurrentUserQuery() {
        return new Field('getCurrentUser').addFieldList(this._getCurrentUserFields());
    }

    getUserRolesQuery() {
        return new Field('getUserRoles').addFieldList(this._getUserRolesFields());
    }

    getContractorQuery() {
        return new Field('getContractorForLoggedInCustomer').addFieldList(this._getContractorFields());
    }

    getContractorStructureQuery() {
        return new Field('getContractorStructure').addFieldList(this._getContractorStructureFields());
    }

    getContractorSubstructuresQuery(currentPage = 1, pageSize = 10) {
        return new Field('getSubstructures')
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('currentPage', 'Int', currentPage)
            .addFieldList([this._getContractorSubStructureItemsFields(), this._getPageInfoField(), 'total_count']);
    }

    _getContractorSubStructureItemsFields() {
        return new Field('items').addFieldList(this._getContractorStructureFields(true));
    }

    getContractorUserListQuery(currentPage = 1, filter = {}) {
        return new Field('getContractorUsers')
            .setAlias('getContractorUserList')
            .addArgument('filter', 'ContractorUsersFilter', filter)
            .addArgument('currentPage', 'Int', currentPage)
            .addFieldList([this._getContractorUserListItemsField(), this._getPageInfoField()]);
    }

    getContractorUserCreateMutation(options) {
        return new Field('createUser')
            .addArgument('input', 'CreateUserInput', options)
            .addFieldList(this._getMutationResponse());
    }

    getContractorUserUpdateMutation(options) {
        return new Field('updateUser')
            .addArgument('input', 'UpdateUserInput', options)
            .addFieldList(this._getMutationResponse());
    }

    _getCurrentUserFields() {
        return ['id', 'firstname', 'lastname', 'email', 'structure_id', 'role_id'];
    }

    _getUserRolesFields() {
        return ['id', 'name'];
    }

    _getContractorUserListItemsField() {
        return new Field('items').addFieldList(this._getContractorUserListItemsFields());
    }

    _getContractorUserListItemsFields() {
        return ['email', 'firstname', 'lastname', 'id', 'is_active', 'role_id', 'structure_id'];
    }

    _getContractorFields() {
        return [
            'city',
            'company',
            'country',
            'customer_group_id',
            'email',
            'id',
            'is_active',
            'phone',
            'postcode',
            'street',
            'type',
            'vat_id',
            this._getContractorMainCustomerField(),
        ];
    }

    _getContractorMainCustomerField() {
        return new Field('main_customer').addFieldList(['firstname', 'lastname']);
    }

    _getContractorStructureField() {
        return new Field('structure').addFieldList(this._getContractorStructureFields());
    }

    _getContractorStructureFields(includeUsersCount) {
        return [
            'city',
            'country',
            'email',
            'id',
            'name',
            'parent_id',
            'parent_name',
            'phone',
            'postcode',
            'province',
            'street',
            'type',
            ...(includeUsersCount ? ['usersCount', 'users'] : []),
        ];
    }

    _getContractorSubstructureField() {
        return new Field('substructures').addFieldList(this._getContractorSubstructureFields());
    }

    _getContractorSubstructureFields() {
        return [
            'city',
            'country',
            'email',
            'id',
            'name',
            'parent_id',
            'parent_name',
            'phone',
            'postcode',
            'province',
            'street',
            'type',
        ];
    }

    _getMutationResponse() {
        return ['id'];
    }

    _getPageInfoField() {
        return new Field('page_info').addFieldList(this._getPageInfoFields());
    }

    _getPageInfoFields() {
        return ['current_page', 'page_size', 'total_pages'];
    }

    getCreateContractorMutation(options) {
        return new Field('createCustomerContactForm')
            .addArgument('form', 'CustomerContactForm!', options)
            .addFieldList(this._getCreateContractorResponse());
    }

    _getCreateContractorResponse() {
        return ['status', 'customerContactFormId'];
    }

    _getContractorMerchantLimit() {
        return new Field('getContractorMerchantLimit').addFieldList(this._getContractorMerchantLimitFields());
    }

    _getContractorMerchantLimitFields() {
        return ['amount', 'amount_used', 'remaining'];
    }
}

export default new ContractorQuery();
