import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import AnnouncementReducer from 'Store/Announcement/Announcement.reducer';
import ContractorReducer from 'Store/Contractor/Contractor.reducer';
import ShowRetailPrices from 'Store/ShowRetailPrices/ShowRetailPrices.reducer';

/** @namespace Pwabb/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    AnnouncementReducer,
    ContractorReducer,
    ShowRetailPrices,
});

export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => store.injectReducer(name, reducer));

    return store;
}
