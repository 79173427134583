/** @namespace Pwabb/Util/Date/convertDateFormat */
export function convertDateFormat(timestamp, date_options = { year: 'numeric', month: 'long', day: 'numeric' }) {
    const date = new Date(timestamp.replace(/-/g, '/'));
    return date.toLocaleDateString(undefined, date_options);
}

/** @namespace Pwabb/Util/Date/getFormattedDate */
export const getFormattedDate = (rawDate = '', separator = '.') => {
    const date = new Date(rawDate.replace(/\s/, 'T'));
    const RADIX = 10;

    const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);

    return [day, month, date.getFullYear()].filter(Boolean).join(separator);
};

/** @namespace Pwabb/Util/Date/getFormattedDateTime */
export const getFormattedDateTime = (
    rawDate = '',
    { dateSeparator, timeSeparator } = { dateSeparator: '-', timeSeparator: ':' }
) => {
    const date = new Date(rawDate.replace(/\s/, 'T'));
    const RADIX = 10;

    const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

    const hour = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);

    return [
        [day, month, date.getFullYear()].filter(Boolean).join(dateSeparator),
        [hour, minutes].filter(Boolean).join(timeSeparator),
    ]
        .filter(Boolean)
        .join(', ');
};
