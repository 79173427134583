import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'AdvoxComponent/ProductCard/ProductCard.container';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Pwabb/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    showOnlyRetailPrices: state.ShowRetailPrices.showPrices,
});

export { mapDispatchToProps };

/** @namespace Pwabb/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        sliderMode: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceProductCardContainer.defaultProps,
        sliderMode: false,
    };

    containerProps() {
        const { sliderMode, showOnlyRetailPrices } = this.props;

        return {
            ...super.containerProps(),
            sliderMode,
            showOnlyRetailPrices,
        };
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product,
        } = this.props;

        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);

        const {
            location: { pathname },
        } = history;

        if (!url) {
            return undefined;
        }

        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category },
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
