import { FieldGroup as AdvoxFieldGroup } from 'AdvoxComponent/FieldGroup/FieldGroup.component';

/** @namespace Pwabb/Component/FieldGroup/Component */
export class FieldGroup extends AdvoxFieldGroup {
    renderLabel() {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        return label;
    }

    // Renders group label under field
    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return subLabel;
    }
}

export default FieldGroup;
