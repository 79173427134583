import { connect } from 'react-redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { ADD_TO_CART } from 'Util/Product';
import { isBackOrderEnabled } from 'Util/Product/Extract';
import { magentoProductTransform } from 'Util/Product/Transform';

/** @namespace Pwabb/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Pwabb/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerProps() {
        const { isMobile, product, quantity, isSignedIn } = this.props;
        const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

        return {
            ...super.containerProps(),
            magentoProduct,
            isSignedIn,
            isMobile,
        };
    }

    async addProductToCart(e) {
        const { product, addToCart } = this.props;

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        this.setState({ isAdding: true });
        const isBackOrderProduct = !!isBackOrderEnabled(product);

        if (!isBackOrderProduct && !this.validate()) {
            this.setState({ isAdding: false });
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart();
            } finally {
                this.setState({ isAdding: false });
            }
        } else {
            const { quantity, cartId, fallbackAddToCart } = this.props;
            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId,
                });
            } finally {
                this.setState({ isAdding: false });
            }
        }

        this.setState({ isAdding: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
