import { FieldSelect as AdvoxFieldSelect } from 'AdvoxComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Pwabb/Component/FieldSelect/Component */
export class FieldSelect extends AdvoxFieldSelect {
    renderOption(option) {
        const { id, label, subLabel, isHovered, value } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            attr: { value: selectedValue },
        } = this.props;

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{ isExpanded, isHovered, isSelected: selectedValue === value }}
                key={id}
                id={`o${id}`}
                role="menuitem"
                onMouseDown={() => handleSelectListOptionClick(option)}
                onTouchStart={() => handleSelectListOptionClick(option)}
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? '0' : '-1'}
            >
                {label}
                {subLabel && <strong>{` ${subLabel}`}</strong>}
            </li>
        );
    }

    renderNativeOption(option) {
        const { id, value, disabled, label, subLabel = '', isAvailable = true } = option;

        const { isDisabled } = this.props;

        return (
            <option key={id} id={id} value={value} disabled={disabled || isDisabled || !isAvailable}>
                {`${label} ${subLabel}`}
            </option>
        );
    }
}

export default FieldSelect;
