import { Field } from 'Util/Query';

/** @namespace Pwabb/Query/Announcement/Query */
export class AnnouncementQuery {
    getAnnouncementQuery({ date, store, place, url }) {
        return new Field('announcement')
            .addArgument('date', 'String', date)
            .addArgument('store', 'Int', store)
            .addArgument('place', 'String', place)
            .addArgument('url', 'String', url)
            .addFieldList(this._getFieldList());
    }

    _getFieldList() {
        return ['background_color', 'can_close', 'content', 'places', 'text_color', 'display_from', 'display_to', 'id'];
    }

    getCurrentLocation(url) {
        return new Field('urlResolver').addArgument('url', 'String!', url).addFieldList(this._getLocationFieldList());
    }

    _getLocationFieldList() {
        return ['type'];
    }
}

export default new AnnouncementQuery();
