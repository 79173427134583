import Check from '@sevenanna-advox-bb/permissions/src/component/Check';

import { MyAccountTabList as SourceMyAccountTabList } from 'AdvoxComponent/MyAccountTabList/MyAccountTabList.component';
import Icons from 'Component/Icons';
import MyAccountTabListItem from 'Component/MyAccountTabListItem';

import './MyAccountTabList.style';

/** @namespace Pwabb/Component/MyAccountTabList/Component */
export class MyAccountTabList extends SourceMyAccountTabList {
    renderTabListItem(tabEntry, index, tabArray) {
        const { activeTab, onTabClick } = this.props;
        const [key, tab] = tabEntry;
        const { section, permissions, isHidden } = tab;
        const nextTab = tabArray[index + 1] || [];
        const { section: nextTabSection = section } = nextTab[1] || {};

        return !isHidden ? (
            <Check permissions={permissions} key={key}>
                <MyAccountTabListItem isActive={activeTab === key} changeActiveTab={onTabClick} tabEntry={tabEntry}>
                    {nextTabSection !== section ? this.renderLine() : null}
                </MyAccountTabListItem>
            </Check>
        ) : null;
    }

    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li key="logout" block="MyAccountTabListItem">
                <button block="MyAccountTabListItem" elem="Button" onClick={handleLogout} role="link">
                    <Icons name="account_logout" />
                    {__('Logout')}
                </button>
            </li>
        );
    }
}

export default MyAccountTabList;
