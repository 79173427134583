/* eslint-disable */

export * from "AdvoxComponent/Icons/Icons.config";

/** @namespace Pwabb/Component/Icons/Config/ERROR */
export const ERROR = ({ fill, ...rest }) => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 9.01611V14.0161M10 19.0161C5.02944 19.0161 1 14.9867 1 10.0161C1 5.04555 5.02944 1.01611 10 1.01611C14.9706 1.01611 19 5.04555 19 10.0161C19 14.9867 14.9706 19.0161 10 19.0161ZM10.0498 6.01611V6.11611L9.9502 6.11609V6.01611H10.0498Z"
            stroke="#DD2D2D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

/** @namespace Pwabb/Component/Icons/Config/CHECK_OK */
export const CHECK_OK = ({ fill, ...rest }) => (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.63623 6.01611L5.87887 10.2588L14.3637 1.77344"
            stroke="#632BFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

/** @namespace Pwabb/Component/Icons/Config/CART */
export const CART = ({ fill, ...rest }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.18005H1.26835C1.74213 1.18005 1.97922 1.18005 2.17246 1.26553C2.34283 1.3409 2.48871 1.46235 2.59375 1.61621C2.71289 1.79072 2.75578 2.02372 2.8418 2.48962L5.00004 14.1801L15.4195 14.1801C15.8739 14.1801 16.1016 14.18 16.2896 14.0999C16.4554 14.0291 16.5989 13.9149 16.7051 13.7692C16.8255 13.604 16.8763 13.3826 16.9785 12.9398L18.5477 6.13977C18.7022 5.4703 18.7796 5.13567 18.6946 4.87268C18.6201 4.64212 18.4639 4.4464 18.256 4.32197C18.0189 4.18005 17.6758 4.18005 16.9887 4.18005H3.5M16 19.1801C15.4477 19.1801 15 18.7323 15 18.1801C15 17.6278 15.4477 17.1801 16 17.1801C16.5523 17.1801 17 17.6278 17 18.1801C17 18.7323 16.5523 19.1801 16 19.1801ZM6 19.1801C5.44772 19.1801 5 18.7323 5 18.1801C5 17.6278 5.44772 17.1801 6 17.1801C6.55228 17.1801 7 17.6278 7 18.1801C7 18.7323 6.55228 19.1801 6 19.1801Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_DASHBOARD */
export const ACCOUNT_DASHBOARD = ({ fill, ...rest }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0C15.1031 0 16 0.895313 16 2V12C16 13.1031 15.1031 14 14 14H2C0.895313 14 0 13.1031 0 12V2C0 0.895313 0.895313 0 2 0H14ZM14 1.5H2C1.72375 1.5 1.5 1.72375 1.5 2V4H14.5V2C14.5 1.72375 14.275 1.5 14 1.5ZM2 12.5H4.5V5.5H1.5V12C1.5 12.275 1.72375 12.5 2 12.5ZM6 12.5H14C14.275 12.5 14.5 12.275 14.5 12V5.5H6V12.5Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_DATA */
export const ACCOUNT_DATA = ({ fill, ...rest }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6594 0.783992C12.5375 -0.0946639 13.9625 -0.0946639 14.8406 0.783992L15.2188 1.16274C16.0969 2.04149 16.0969 3.46618 15.2188 4.3443L9.05625 10.5099C8.78437 10.7787 8.45 10.9755 8.08125 11.0818L4.95625 11.9755C4.69375 12.0505 4.4125 11.9755 4.21875 11.7568C4.02812 11.5912 3.95312 11.3099 4.02812 11.0474L4.92188 7.92242C5.02812 7.55367 5.225 7.2193 5.49375 6.94742L11.6594 0.783992ZM13.7531 1.84462C13.4875 1.5518 13.0125 1.5518 12.7188 1.84462L11.8094 2.75368L13.25 4.1943L14.1594 3.2568C14.4531 2.99118 14.4531 2.51618 14.1594 2.22337L13.7531 1.84462ZM6.36562 8.33492L5.84062 10.163L7.66875 9.63805C7.79375 9.60367 7.90313 9.53805 7.99375 9.44742L12.1906 5.25367L10.75 3.81305L6.55625 8.00992C6.46563 8.10055 6.4 8.20992 6.36562 8.33492ZM6.25 2.00368C6.66563 2.00368 7 2.33962 7 2.75368C7 3.1693 6.66563 3.50368 6.25 3.50368H2.75C2.05969 3.50368 1.5 4.06305 1.5 4.75368V13.2537C1.5 13.9443 2.05969 14.5037 2.75 14.5037H11.25C11.9406 14.5037 12.5 13.9443 12.5 13.2537V9.75367C12.5 9.33805 12.8344 9.00367 13.25 9.00367C13.6656 9.00367 14 9.33805 14 9.75367V13.2537C14 14.7724 12.7688 16.0037 11.25 16.0037H2.75C1.23125 16.0037 0 14.7724 0 13.2537V4.75368C0 3.23493 1.23125 2.00368 2.75 2.00368H6.25Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_ORDERS */
export const ACCOUNT_ORDERS = ({ fill, ...rest }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 15.25C0 15.6656 0.335942 16 0.750009 16C1.16408 16 1.47189 15.6641 1.47189 15.25V12.2537C1.09689 11.9563 0.0156252 11.0719 0 11.0594V15.25ZM2.50034 3.00031C3.32848 3.00031 4.00067 2.32812 4.00067 1.5C4.00067 0.671875 3.32817 0 2.50034 0C1.67252 0 1.00001 0.672187 1.00001 1.50031C1.00001 2.32844 1.67221 3.00031 2.50034 3.00031ZM15.9721 12.0469C15.8608 11.6478 15.4486 11.4131 15.0474 11.5269L10.5754 12.7794C10.2561 12.3678 9.78293 12.0884 9.23917 12.0231L7.47197 5.55312C7.36571 5.15312 6.95946 4.9125 6.5532 5.025C6.1532 5.13438 5.9157 5.54688 6.02507 5.94688L6.44758 7.49563H4.94381L3.97192 5.07375C3.69848 4.42125 3.06566 3.99563 2.35472 3.99563H1.77658C0.785322 3.99688 0 4.78125 0 5.74687V8.94687C0 9.18516 0.0937511 9.86875 0.664071 10.3219L3.05441 12.2087C3.2302 12.3494 3.3552 12.5447 3.40598 12.7675L4.01911 15.4159C4.10108 15.7753 4.46818 16.08 4.91756 15.9784C5.32006 15.8847 5.57382 15.4822 5.48007 15.08L4.81225 12.1737C4.7224 11.7869 4.50365 11.4394 4.19099 11.1931L2.99972 10.2556V6.7125L3.69504 8.38437C3.85005 8.75937 4.20943 9 4.61568 9H6.85758L7.78947 12.4156C7.31259 12.7812 6.97196 13.3531 6.97196 14C6.97196 15.1047 7.8676 16 8.94386 16C9.97137 16 10.8076 15.2225 10.9208 14.2253L15.3958 12.9722C15.8502 12.8594 16.0846 12.4469 15.9721 12.0469ZM8.97198 14.5C8.69636 14.5 8.5001 14.2757 8.5001 14C8.5001 13.7243 8.72442 13.5 8.97198 13.5C9.24767 13.5 9.47199 13.7243 9.47199 14C9.47199 14.2757 9.27511 14.5 8.97198 14.5Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_STRUCTURE */
export const ACCOUNT_STRUCTURE = ({ fill, ...rest }) => (
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.97917 3.65625C2.97917 3.34547 3.22156 3.09375 3.52083 3.09375H5.14583C5.44375 3.09375 5.6875 3.34547 5.6875 3.65625V5.34375C5.6875 5.65312 5.44375 5.90625 5.14583 5.90625H3.52083C3.22156 5.90625 2.97917 5.65312 2.97917 5.34375V3.65625ZM9.47917 3.09375C9.77708 3.09375 10.0208 3.34547 10.0208 3.65625V5.34375C10.0208 5.65312 9.77708 5.90625 9.47917 5.90625H7.85417C7.55625 5.90625 7.3125 5.65312 7.3125 5.34375V3.65625C7.3125 3.34547 7.55625 3.09375 7.85417 3.09375H9.47917ZM2.97917 8.15625C2.97917 7.84688 3.22156 7.59375 3.52083 7.59375H5.14583C5.44375 7.59375 5.6875 7.84688 5.6875 8.15625V9.84375C5.6875 10.1531 5.44375 10.4062 5.14583 10.4062H3.52083C3.22156 10.4062 2.97917 10.1531 2.97917 9.84375V8.15625ZM9.47917 7.59375C9.77708 7.59375 10.0208 7.84688 10.0208 8.15625V9.84375C10.0208 10.1531 9.77708 10.4062 9.47917 10.4062H7.85417C7.55625 10.4062 7.3125 10.1531 7.3125 9.84375V8.15625C7.3125 7.84688 7.55625 7.59375 7.85417 7.59375H9.47917ZM0 2.25C0 1.00723 0.969922 0 2.16667 0H10.8333C12.0284 0 13 1.00723 13 2.25V15.75C13 16.991 12.0284 18 10.8333 18H2.16667C0.969922 18 0 16.991 0 15.75V2.25ZM1.625 2.25V15.75C1.625 16.0594 1.8674 16.3125 2.16667 16.3125H4.875V14.0625C4.875 13.1309 5.60286 12.375 6.5 12.375C7.39714 12.375 8.125 13.1309 8.125 14.0625V16.3125H10.8333C11.1313 16.3125 11.375 16.0594 11.375 15.75V2.25C11.375 1.93922 11.1313 1.6875 10.8333 1.6875H2.16667C1.8674 1.6875 1.625 1.93922 1.625 2.25Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_USERS */
export const ACCOUNT_USERS = ({ fill, ...rest }) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 3H4V2C4 0.895313 4.89531 0 6 0H10C11.1031 0 12 0.895313 12 2V3H14C15.1047 3 16 3.89687 16 5V13C16 14.1047 15.1047 15 14 15H2C0.895312 15 0 14.1047 0 13V5C0 3.89687 0.896875 3 2 3ZM10.5 2C10.5 1.72381 10.2761 1.5 10 1.5H6C5.72388 1.5 5.5 1.72381 5.5 2V3H10.5V2ZM1.5 13C1.5 13.275 1.72497 13.5 2 13.5H14C14.275 13.5 14.5 13.275 14.5 13V5C14.5 4.72497 14.275 4.5 14 4.5H2C1.72497 4.5 1.5 4.72497 1.5 5V13Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_INVOICES */
export const ACCOUNT_INVOICES = ({ fill, ...rest }) => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33333 5.875C8.63889 5.875 8.88889 6.12813 8.88889 6.4375C8.88889 6.74687 8.63889 7 8.33333 7H3.88889C3.58333 7 3.33333 6.74687 3.33333 6.4375C3.33333 6.12813 3.58333 5.875 3.88889 5.875H8.33333ZM3.33333 9.8125C3.33333 9.50313 3.58333 9.25 3.88889 9.25H16.1111C16.4167 9.25 16.6667 9.50313 16.6667 9.8125C16.6667 10.1219 16.4167 10.375 16.1111 10.375H3.88889C3.58333 10.375 3.33333 10.1219 3.33333 9.8125ZM11.1111 4.46875C11.1111 3.6918 11.7326 3.0625 12.5 3.0625H15.2778C16.0451 3.0625 16.6667 3.6918 16.6667 4.46875V6.15625C16.6667 6.9332 16.0451 7.5625 15.2778 7.5625H12.5C11.7326 7.5625 11.1111 6.9332 11.1111 6.15625V4.46875ZM12.2222 4.46875V6.15625C12.2222 6.31094 12.3472 6.4375 12.5 6.4375H15.2778C15.4306 6.4375 15.5556 6.31094 15.5556 6.15625V4.46875C15.5556 4.31406 15.4306 4.1875 15.2778 4.1875H12.5C12.3472 4.1875 12.2222 4.31406 12.2222 4.46875ZM0 2.5C0 1.25723 0.994792 0.25 2.22222 0.25H17.7778C19.0035 0.25 20 1.25723 20 2.5V11.5C20 12.741 19.0035 13.75 17.7778 13.75H2.22222C0.994792 13.75 0 12.741 0 11.5V2.5ZM1.11111 2.5V11.5C1.11111 12.1223 1.60868 12.625 2.22222 12.625H17.7778C18.3924 12.625 18.8889 12.1223 18.8889 11.5V2.5C18.8889 1.87773 18.3924 1.375 17.7778 1.375H2.22222C1.60868 1.375 1.11111 1.87773 1.11111 2.5Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/ACCOUNT_LOGOUT */
export const ACCOUNT_LOGOUT = ({ fill, ...rest }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 13.25C6 13.6656 5.66563 14 5.25 14H3C1.34312 14 0 12.6569 0 11V3C0 1.34312 1.34312 0 3 0H5.25C5.66563 0 6 0.335625 6 0.75C6 1.16406 5.66563 1.5 5.25 1.5H3C2.175 1.5 1.5 2.175 1.5 3V11C1.5 11.825 2.175 12.5 3 12.5H5.25C5.66563 12.5 6 12.8344 6 13.25ZM15.7969 6.4875L11.825 2.2375C11.5408 1.93475 11.0662 1.92125 10.7653 2.20528C10.4636 2.48847 10.4491 2.96309 10.7331 3.26497L13.5125 6.25H5.72188C5.33438 6.25 5 6.5875 5 7C5 7.4125 5.33594 7.75 5.72188 7.75H13.4844L10.6744 10.7362C10.3902 11.038 10.4048 11.5125 10.7066 11.7959C10.8812 11.9312 11.0656 12 11.2219 12C11.4211 12 11.6203 11.9209 11.7678 11.7637L15.7397 7.51369C16.0688 7.225 16.0688 6.775 15.7969 6.4875Z" fill={fill} />
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/REORDER */
export const REORDER = ({ fill, ...rest }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1843 2.34019C10.1063 -0.738477 5.14701 -0.777144 2.01368 2.21019L0.594343 0.704856V5.99686H5.58368L4.20634 4.53619C6.08634 2.73219 9.07101 2.75219 10.9217 4.60286C13.8157 7.49686 12.0797 12.7969 7.26101 12.7969V15.9969C9.73301 15.9969 11.6703 15.1675 13.1843 13.6535C16.3083 10.5295 16.3083 5.46419 13.1843 2.34019Z" fill="#000"/>
  </svg>
)

/** @namespace Pwabb/Component/Icons/Config/FILE_DOWNLOAD */
export const DATE_PICKER = ({ fill, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none" {...rest}>
      <path d="M7.89286 10C8.22679 10 8.5 10.2812 8.5 10.625V14.375C8.5 14.7188 8.22679 15 7.89286 15H4.25C3.91607 15 3.64286 14.7188 3.64286 14.375V10.625C3.64286 10.2812 3.91607 10 4.25 10H7.89286ZM4.85714 0C5.36183 0 5.76786 0.419922 5.76786 0.9375V2.5H11.2321V0.9375C11.2321 0.419922 11.6382 0 12.1429 0C12.6475 0 13.0536 0.419922 13.0536 0.9375V2.5H14.5714C15.9109 2.5 17 3.61914 17 5V17.5C17 18.8789 15.9109 20 14.5714 20H2.42857C1.08717 20 0 18.8789 0 17.5V5C0 3.61914 1.08717 2.5 2.42857 2.5H3.94643V0.9375C3.94643 0.419922 4.35246 0 4.85714 0ZM15.1786 7.5H1.82143V17.5C1.82143 17.8438 2.09313 18.125 2.42857 18.125H14.5714C14.9054 18.125 15.1786 17.8438 15.1786 17.5V7.5Z" fill={fill}/>
  </svg>
);

/** @namespace Pwabb/Component/Icons/Config/WARNING_FILL */
export const WARNING_FILL = ({ fill, ...rest }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM8 4C7.87361 4.00007 7.74863 4.02662 7.63312 4.07793C7.51761 4.12924 7.41413 4.20418 7.32934 4.29791C7.24456 4.39165 7.18035 4.5021 7.14084 4.62217C7.10134 4.74223 7.08743 4.86923 7.1 4.995L7.45 8.502C7.46176 8.63977 7.5248 8.76811 7.62664 8.86164C7.72849 8.95516 7.86173 9.00705 8 9.00705C8.13827 9.00705 8.27151 8.95516 8.37336 8.86164C8.4752 8.76811 8.53824 8.63977 8.55 8.502L8.9 4.995C8.91257 4.86923 8.89866 4.74223 8.85915 4.62217C8.81965 4.5021 8.75544 4.39165 8.67066 4.29791C8.58587 4.20418 8.48239 4.12924 8.36688 4.07793C8.25137 4.02662 8.12639 4.00007 8 4ZM8.002 10C7.73678 10 7.48243 10.1054 7.29489 10.2929C7.10736 10.4804 7.002 10.7348 7.002 11C7.002 11.2652 7.10736 11.5196 7.29489 11.7071C7.48243 11.8946 7.73678 12 8.002 12C8.26722 12 8.52157 11.8946 8.70911 11.7071C8.89664 11.5196 9.002 11.2652 9.002 11C9.002 10.7348 8.89664 10.4804 8.70911 10.2929C8.52157 10.1054 8.26722 10 8.002 10Z"
        fill={fill}
      />
  </svg>
);

/** @namespace Pwabb/Component/Icons/Config/FILE_DOWNLOAD */
export const FILE_PDF_DOWNLOAD = ({ fill, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none" {...rest}>
      <path d="M15.8145 17.5C15.8145 18.8789 14.6934 20 13.3145 20H3.31445C1.93359 20 0.814453 18.8789 0.814453 17.5H2.06445C2.06445 18.1914 2.62422 18.75 3.31445 18.75H13.3145C14.0059 18.75 14.5645 18.1914 14.5645 17.5H15.8145ZM10.1895 7.5C9.1543 7.5 8.31445 6.66016 8.31445 5.625V1.25H3.31445C2.62422 1.25 2.06445 1.80977 2.06445 2.5V8.75H0.814453V2.5C0.814453 1.11914 1.93359 0 3.31445 0H9.41211C9.9082 0 10.3887 0.197539 10.7402 0.549219L15.2637 5.07422C15.6152 5.42578 15.8145 5.90625 15.8145 6.40234V8.75H14.5645V7.5H10.1895ZM14.3809 5.96094L9.85352 1.4332C9.77539 1.35234 9.67383 1.29609 9.56445 1.26875V5.625C9.56445 5.96875 9.8457 6.25 10.1895 6.25H14.5449C14.5176 6.14062 14.4629 6.03906 14.3809 5.96094ZM4.25195 10C5.45898 10 6.43945 10.9805 6.43945 12.1875C6.43945 13.3945 5.45898 14.375 4.25195 14.375H3.93945V15.625C3.93945 15.9688 3.65977 16.25 3.31445 16.25C2.96914 16.25 2.68945 15.9688 2.68945 15.625V10.625C2.68945 10.2812 2.96914 10 3.31445 10H4.25195ZM5.18945 12.1875C5.18945 11.668 4.77148 11.25 4.25195 11.25H3.93945V13.125H4.25195C4.77148 13.125 5.18945 12.707 5.18945 12.1875ZM7.06445 10.625C7.06445 10.2812 7.3457 10 7.68945 10H8.62695C9.66211 10 10.502 10.8398 10.502 11.875V14.375C10.502 15.4102 9.66211 16.25 8.62695 16.25H7.68945C7.3457 16.25 7.06445 15.9688 7.06445 15.625V10.625ZM8.31445 15H8.62695C8.9707 15 9.25195 14.7188 9.25195 14.375V11.875C9.25195 11.5312 8.9707 11.25 8.62695 11.25H8.31445V15ZM13.9395 10C14.2832 10 14.5645 10.2812 14.5645 10.625C14.5645 10.9688 14.2832 11.25 13.9395 11.25H12.6895V12.5H13.9395C14.2832 12.5 14.5645 12.7812 14.5645 13.125C14.5645 13.4688 14.2832 13.75 13.9395 13.75H12.6895V15.625C12.6895 15.9688 12.4082 16.25 12.0645 16.25C11.7207 16.25 11.4395 15.9688 11.4395 15.625V10.625C11.4395 10.2812 11.7207 10 12.0645 10H13.9395Z" fill={fill}/>
  </svg>
);

/** @namespace Pwabb/Component/Icons/Config/FILE_DOWNLOAD */
export const FILE_DOWNLOAD = ({ fill, ...rest }) => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 1.26953V5.40007C11 5.96012 11 6.24015 11.109 6.45406C11.2049 6.64222 11.3578 6.7952 11.546 6.89108C11.7599 7.00007 12.0399 7.00007 12.6 7.00007H16.7305M6 14L9 17M9 17L12 14M9 17L9 11M11 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H12.2C13.8802 21 14.7202 21 15.362 20.673C15.9265 20.3854 16.3854 19.9265 16.673 19.362C17 18.7202 17 17.8802 17 16.2V7L11 1Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
