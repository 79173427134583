import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { STOCK_TYPE } from 'Component/Product/Stock.config';

export * from 'AdvoxUtil/Product/Extract';
export const BACKORDERS_DEFAULT_VALUE = '0';
export const BACKORDERS_ACTIVE_VALUE = '1';

/** @namespace Pwabb/Util/Product/Extract/isBackOrderEnabled */
export const isBackOrderEnabled = (product) => {
    const { stock_item: { backorders = BACKORDERS_DEFAULT_VALUE } = {}, variants } = product;

    if (variants?.length && backorders === BACKORDERS_DEFAULT_VALUE) {
        return variants.find((variant) => variant?.stock_item?.backorders === BACKORDERS_ACTIVE_VALUE);
    }

    return backorders === BACKORDERS_ACTIVE_VALUE;
};

/** @namespace Pwabb/Util/Product/Extract/getProductInStock */
export const getProductInStock = (product, parentProduct = {}) => {
    if (!product) {
        return false;
    }

    const { type_id: type, variants = [], items = [], stock_item: { in_stock: inStock = false } = {} } = product;

    if (type === PRODUCT_TYPE.bundle) {
        const { items = [] } = product;
        const requiredItems = items.filter(({ required }) => required);
        const requiredItemsInStock = requiredItems.filter(({ options }) =>
            options.some(({ product }) => getProductInStock(product))
        );

        return (inStock && requiredItemsInStock.length === requiredItems.length) || !isBackOrderEnabled(product);
    }

    if (type === PRODUCT_TYPE.configurable && parentProduct === product) {
        return (
            (inStock && !!variants.some((variant) => getProductInStock(variant, product))) ||
            isBackOrderEnabled(product)
        );
    }

    const { type_id: parentTypeId = false } = parentProduct;

    if (parentTypeId === PRODUCT_TYPE.configurable && parentProduct !== product) {
        const { stock_item: { in_stock: parentInStock = false } = {}, stock_status: parentStockStatus } = parentProduct;

        return (
            (parentInStock && parentStockStatus !== STOCK_TYPE.OUT_OF_STOCK && getProductInStock(product)) ||
            isBackOrderEnabled(product)
        );
    }

    if (type === PRODUCT_TYPE.grouped) {
        return (inStock && !!items.some(({ product }) => getProductInStock(product))) || isBackOrderEnabled(product);
    }

    const { stock_status: stockStatus } = product;

    return (
        (stockStatus !== STOCK_TYPE.OUT_OF_STOCK && (inStock || stockStatus === STOCK_TYPE.IN_STOCK)) ||
        isBackOrderEnabled(product)
    );
};
