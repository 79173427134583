import Check from '@sevenanna-advox-bb/permissions/src/component/Check';
import { lazy, Suspense } from 'react';

import { MyAccount as AdvoxMyAccount } from 'AdvoxRoute/MyAccount/MyAccount.component';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import MyAccountTabList from 'Component/MyAccountTabList';
import NoMatch from 'Route/NoMatch';
import NoMatchHandler from 'Route/NoMatchHandler';
import { ACCOUNT_DATA, COMPANY_STRUCTURE, CONTRACTOR_USER_PAGE, FINANCIAL_DOCUMENT } from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';

import './MyAccount.style';

export * from 'AdvoxRoute/MyAccount/MyAccount.component';

export const MyAccountFinancialDocument = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-financial-document" */
        'Component/MyAccountFinancialDocument'
    )
);

export const MyAccountCompanyStructure = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-company-structure" */
        'Component/MyAccountCompanyStructure'
    )
);
export const MyAccountData = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "account-data" */ 'Component/MyAccountData')
);

export const MyAccountContractorUser = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-contractor-user" */
        'Component/MyAccountContractorUser'
    )
);

/** @namespace Pwabb/Route/MyAccount/Component */
export class MyAccount extends AdvoxMyAccount {
    static propTypes = {
        ...AdvoxMyAccount.propTypes,
    };

    renderMap = {
        ...this.renderMap,
        [ACCOUNT_DATA]: MyAccountData,
        [CONTRACTOR_USER_PAGE]: MyAccountContractorUser,
        [FINANCIAL_DOCUMENT]: MyAccountFinancialDocument,
        [COMPANY_STRUCTURE]: MyAccountCompanyStructure,
    };

    renderContent() {
        const { activeTab, tabMap, changeActiveTab, onSignOut, isEditingActive, setTabSubheading, changeTabName } =
            this.props;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        const TabContent = this.renderMap[activeTab];
        const { heading, tabName, hiddenHeader, permissions = [] } = tabMap[activeTab];

        return (
            <Check permissions={permissions} fallback={<NoMatch />}>
                <ContentWrapper label={__('My Account page')} wrapperMix={{ block: 'MyAccount', elem: 'Wrapper' }}>
                    <MyAccountTabList
                        tabMap={tabMap}
                        activeTab={activeTab}
                        changeActiveTab={changeActiveTab}
                        onSignOut={onSignOut}
                    />
                    <div block="MyAccount" elem="TabContent">
                        {!hiddenHeader && (heading ?? tabName) ? (
                            <h2 block="MyAccount" elem="Heading">
                                {heading ?? tabName}
                                {this.renderSubHeading()}
                            </h2>
                        ) : null}
                        <Suspense fallback={<Loader />}>
                            <TabContent
                                changeTabName={changeTabName}
                                isEditingActive={isEditingActive}
                                setTabSubheading={setTabSubheading}
                                tabMap={tabMap}
                            />
                        </Suspense>
                    </div>
                </ContentWrapper>
            </Check>
        );
    }

    render() {
        return (
            <NoMatchHandler>
                <main block="MyAccount">{this.renderContent()}</main>
            </NoMatchHandler>
        );
    }
}

export default MyAccount;
