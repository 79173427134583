export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';

export const ANNOUNCEMENT_NOTICE = 'ANNOUNCEMENT_NOTICE';

/** @namespace Pwabb/Store/Announcement/Action/setAnnouncement */
export const setAnnouncement = (announcement) => ({
    type: SET_ANNOUNCEMENT,
    announcement,
});
