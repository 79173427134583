import { DiscountLabel as AdvoxDiscountLabel } from 'AdvoxComponent/DiscountLabel/DiscountLabel.component';

/** @namespace Pwabb/Component/DiscountLabel/Component */
export class DiscountLabel extends AdvoxDiscountLabel {
    render() {
        const { value, isProductCard, ...rest } = this.props;

        return value ? (
            <span block="DiscountLabel" {...rest} mods={{ secondary: isProductCard }}>
                {!isProductCard && __('Discount')} {`${value}%`}
            </span>
        ) : null;
    }
}

export default DiscountLabel;
