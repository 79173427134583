import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer,
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';
import { CustomerType } from 'Type/Account.type';

export * from 'SourceComponent/MyAccountAddressTable';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwabb/Component/MyAccountAddressTable/Container */
export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    static propTypes = {
        ...SourceMyAccountAddressTableContainer.propTypes,
        customer: CustomerType,
    };

    static defaultProps = {
        ...SourceMyAccountAddressTableContainer.defaultProps,
        customer: {},
    };

    containerProps() {
        const { customer, contractor } = this.props;

        return { ...super.containerProps(), customer, contractor };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
