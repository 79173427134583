import { ProductWishlistButton as AdvoxProductWishlistButton } from 'AdvoxComponent/ProductWishlistButton/ProductWishlistButton.component';
import Icons from 'Component/Icons/Icons.container';

/** @namespace Pwabb/Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends AdvoxProductWishlistButton {
    onClick(e) {
        const { magentoProduct, isInWishlist, addToWishlist, removeFromWishlist, showSelectOptionsNotification } =
            this.props;

        e.preventDefault();
        e.stopPropagation();

        if (showSelectOptionsNotification) {
            showSelectOptionsNotification();
            return null;
        }

        if (!isInWishlist) {
            return addToWishlist(magentoProduct);
        }

        return removeFromWishlist(magentoProduct);
    }

    renderButton() {
        const { isDisabled, isInWishlist } = this.props;

        return (
            <button
                block="ProductWishlistButton"
                elem="Button"
                mods={{ isInWishlist, isDisabled }}
                title={this.getTitle()}
                onClick={this.onClick}
            >
                <Icons name="heart" width="20" height="20" />
            </button>
        );
    }
}

export default ProductWishlistButton;
