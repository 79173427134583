import { Route } from 'react-router-dom';

import { MyAccount, Router as AdvoxRouter, withStoreRegex } from 'AdvoxComponent/Router/Router.component';
import { ACCOUNT_DATA, COMPANY_STRUCTURE, CONTRACTOR_USER_PAGE, FINANCIAL_DOCUMENT } from 'Type/Account.type';

import {
    MY_ACCOUNT_COMPANY_STRUCTURE,
    MY_ACCOUNT_CONTRACTOR_USER,
    MY_ACCOUNT_DATA,
    MY_ACCOUNT_FINANCIAL_DOCUMENT,
    SWITCH_ITEMS_TYPE,
} from './Router.config';

export * from 'AdvoxComponent/Router/Router.component';

/** @namespace Pwabb/Component/Router/Component */
export class Router extends AdvoxRouter {
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account-data')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_DATA} />}
                />
            ),
            position: 96,
            name: MY_ACCOUNT_DATA,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/contractor/user/:action?/:id?')}
                    render={(props) => <MyAccount {...props} selectedTab={CONTRACTOR_USER_PAGE} />}
                />
            ),
            position: 97,
            name: MY_ACCOUNT_CONTRACTOR_USER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/financial/document/history')}
                    render={(props) => <MyAccount {...props} selectedTab={FINANCIAL_DOCUMENT} />}
                />
            ),
            position: 98,
            name: MY_ACCOUNT_FINANCIAL_DOCUMENT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/company/structure')}
                    render={(props) => <MyAccount {...props} selectedTab={COMPANY_STRUCTURE} />}
                />
            ),
            position: 99,
            name: MY_ACCOUNT_COMPANY_STRUCTURE,
        },
    ];
}

export default Router;
