import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import RelatedProductsByAttribute from './RelatedProductsByAttribute.component';

/** @namespace Pwabb/Component/RelatedProductsByAttribute/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    productUrlSuffix: state.ConfigReducer.product_url_suffix,
});

/** @namespace Pwabb/Component/RelatedProductsByAttribute/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwabb/Component/RelatedProductsByAttribute/Container */
export class RelatedProductsByAttributeContainer extends PureComponent {
    static propTypes = {
        productUrlSuffix: PropTypes.string,
        label: PropTypes.string,
        products: PropTypes.array,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        variant: PropTypes.oneOf(['primary', 'secondary']),
    };

    static defaultProps = {
        productUrlSuffix: '',
        label: '',
        products: [],
        variant: 'primary',
        onMouseEnter: () => {},
        onMouseLeave: () => {},
    };

    containerProps() {
        const { productUrlSuffix, products, label, onMouseEnter, onMouseLeave, variant } = this.props;

        return {
            productUrlSuffix,
            products,
            label,
            onMouseEnter,
            onMouseLeave,
            variant,
        };
    }

    render() {
        return <RelatedProductsByAttribute {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProductsByAttributeContainer);
