export const URL_REWRITE = 'url-rewrite';

export const getPageType = () => {
  const { urlRewrite, currentRouteName } = window;

  if (currentRouteName === URL_REWRITE) {
    if (typeof urlRewrite === 'undefined') {
      return '';
    }

    if (urlRewrite.notFound) {
      return 'notfound';
    }

    return (urlRewrite.type || '').toLowerCase();
  }

  return (currentRouteName || '').toLowerCase();
};
