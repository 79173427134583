import { MyAccountCreateAccount as SourceMyAccountCreateAccount } from 'AdvoxComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import Consents from 'Component/Consents';
import { CONSENTS_NEWSLETTER, CONSENTS_REGISTER } from 'Component/Consents/Consents.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { getRegistrationFields } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.form.js';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Pwabb/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccount extends SourceMyAccountCreateAccount {
    renderSubmitButton() {
        const { displayedAsPage, isLoading } = this.props;

        return (
            <div block="MyAccountOverlay" elem="Actions">
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                        block="Button"
                        type="submit"
                        disabled={isLoading}
                        mods={{ size: !displayedAsPage && 'medium' }}
                    >
                        {__('Create Account')}
                    </button>
                </div>
                <div block="MyAccountOverlay" elem="AdditionalActions">
                    <span block="MyAccountOverlay" elem="AdditionalLabel">
                        {__('Already have an account?')}
                    </span>
                    <Link
                        to={appendWithStoreCode(ACCOUNT_LOGIN_URL)}
                        mix={{ block: 'MyAccountOverlay', elem: 'SignInLink' }}
                    >
                        {__('Sign In')}
                    </Link>
                </div>
            </div>
        );
    }

    renderCreateAccountRegistrationFields() {
        const { isCheckingNip } = this.props;

        return (
            <>
                <Loader isLoading={isCheckingNip} />
                {getRegistrationFields(this.props)}
            </>
        );
    }

    renderTermsAndConditions() {
        return (
            <fieldset block="MyAccountOverlay" elem="Terms">
                {this.renderRegisterContents()}
                {this.renderNewsletterConsents()}
            </fieldset>
        );
    }

    renderNewsletterConsents() {
        const { newsletterActive, consentsNewsletterValidate, consentsNewsletterRef } = this.props;

        if (!newsletterActive) {
            return null;
        }

        return (
            <Consents
                type={CONSENTS_NEWSLETTER}
                currentPlace="account-create"
                validateConsents={consentsNewsletterValidate}
                ref={consentsNewsletterRef}
                requireInfo={false}
            />
        );
    }

    renderRegisterContents() {
        return <Consents type={CONSENTS_REGISTER} currentPlace="account-create" requireInfo={false} />;
    }

    renderCreateAccountForm() {
        const { onError, onSuccess } = this.props;

        return (
            <Form key="create-account" onSubmit={onSuccess} onError={onError}>
                {this.renderCreateAccountRegistrationFields()}
                {this.renderTermsAndConditions()}
                {this.renderSubmitButton()}
            </Form>
        );
    }

    render() {
        return (
            <div block="MyAccountCreateAccount">
                {this.renderCreateAccountForm()}
                {this.renderAdditionalField()}
            </div>
        );
    }
}

export default MyAccountCreateAccount;
