export * from 'AdvoxComponent/Router/Router.config';

export const PAYMENT_THANKYOU = 'PAYMENT_THANKYOU';

export const BRANDS_PAGE_URL_PREFIX = __('brands');
export const BRAND_PAGE_URL_PREFIX = __('brand');
export const MY_ACCOUNT_DATA = 'MY_ACCOUNT_DATA';
export const MY_ACCOUNT_CONTRACTOR_USER = 'MY_ACCOUNT_CONTRACTOR_USER';
export const MY_ACCOUNT_FINANCIAL_DOCUMENT = 'MY_ACCOUNT_FINANCIAL_DOCUMENT';
export const MY_ACCOUNT_COMPANY_STRUCTURE = 'MY_ACCOUNT_COMPANY_STRUCTURE';
